<template>
  <div>
    <!-- <v-row>
      <v-col cols="12"> -->
    <v-card style="width: 800px">
      <v-card-title
        justify="center"
        class="text-h5 font-weight-bold justify-center"
        :style="'background-color: ' + this.$vuetify.theme.themes.light.primary + '; color: white'"
      >
        Eliminar {{ tipo }}
      </v-card-title>

      <v-card-text class="text-center my-5" color="primary"
        ><span class="text-h6 mt-4"> ¿Seguro que desea eliminar {{ !masculino ? 'la' : 'el' }} {{ tipo }} ? </span>

        <v-img class="my-5" src="@/assets/images/warning.png" contain height="150"></v-img>
        <span class="mt-4"> (esta acción es irreversible) </span>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="$emit('cancelar')"> <v-icon>mdi-chevron-left</v-icon> Volver </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('confirmar')"> ELIMINAR {{ tipo }} </v-btn>
      </v-card-actions>
    </v-card>
    <!--  </v-col>
    </v-row> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'popUpEliminar',
  props: ['tipo', 'nombre', 'masculino'],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>
