<template>
  <div>
    <v-row>
      <v-col
        align="center"
        class="pb-0 pl-0 "
        :class="[tipo != 'locales' ? 'mb-4' : '']"
        justify="start"
        style="border-bottom: 2px solid #2f2480"
        :style="
          tipo === 'Tipos de venta y métodos de pago' || tipo === 'Cierres de caja'
            ? 'text-transform: none;'
            : 'text-transform: capitalize;'
        "
      >
        <h2 class="primary--text" style="text-align:start">{{ tipo }}</h2>
      </v-col>
      <v-col
        v-if="tipo == 'locales'"
        cols="auto"
        class="pb-0"
        align="center"
        justify="space-between"
        style="text-transform: capitalize;border-bottom: 2px solid #2f2480"
      >
        <v-row dense align="end">
          <v-col class="pb-0" style="display:flex">
            <span class="primary--text" style="align-self:end; text-transform:none;">
              Ordenar por:
            </span>
          </v-col>
          <v-col cols="auto" class="pb-0" style="width:180px;">
            <v-select
              :items="items"
              item-text="text"
              class="primary--text"
              style="padding-top: 1px;"
              item-value="id"
              v-model="ordenarPor"
              hide-details
            >
              <template v-slot:selection="{ item }">
                <span class="primary--text" style="align-self:end; text-transform:none;padding-bottom:2px">{{
                  item.text
                }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="tipo == 'locales'"
      justify="center"
      align="center"
      style="min-height: 100px;
    background-color: #edf1fe;
    border: 2px dashed #2f2480;
    cursor:pointer;
    border-radius: 5px;
    margin-top: 30px;"
      class="buttonNew"
      @click="$emit('abrirCrear')"
    >
      <v-col cols="auto">
        <v-icon class="primary--text" style="font-size:60px">mdi-plus-circle-outline</v-icon>
      </v-col>
      <v-col cols="auto">
        <span
          class="primary--text"
          style="align-self:end; text-transform:none;padding-bottom:2px; font-size:25px; font-weight: bold"
        >
          Pulse aquí para añadir un local
        </span>
      </v-col>
    </v-row>
    <v-row v-if="tipo == 'locales'">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <slot />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="tipo != 'locales'">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <slot />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'CabeceraGestion',
  components: {},
  props: {
    tipo: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ordenarPor: 0,
      items: [
        {
          id: 0,
          text: 'Código de local'
        },
        {
          id: 1,
          text: 'Nombre de local'
        },
        {
          id: 2,
          text: 'Gerente'
        }
      ]
    }
  },
  watch: {
    ordenarPor(newVal) {
      this.$emit('ordenar', newVal)
    }
  }
}
</script>
<style lang="sass">
.buttonNew:hover
  background-color: rgb(202 213 249) !important
</style>
