<template>
  <div>
    <!-- <v-row>
      <v-col cols="12"> -->
    <v-card style="width: 800px">
      <v-card-title
        justify="center"
        class="text-h5 font-weight-bold justify-center"
        :style="'background-color: ' + this.$vuetify.theme.themes.light.primary + '; color: white'"
      >
        {{ accionCamel }} {{ elemento }}
      </v-card-title>

      <v-card-text class="text-center my-5" color="primary"
        ><span class="text-h6 mt-4"> ¿Seguro que desea {{ accion }} {{ articulo }} {{ elemento }}? </span>

        <v-textarea
          v-if="mostrarObservaciones"
          name="input-7-1"
          variant="filled"
          label="Observaciones"
          auto-grow
          v-model="observaciones"
        ></v-textarea>

        <span class="mt-4" style="display: block;" v-if="mostrarIrreversible"> (esta acción es irreversible) </span>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="$emit('cancelar')"> <v-icon>mdi-chevron-left</v-icon> Volver </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="$emit('confirmar', elemento == 'Nuva coins' ? cantidad : mostrarObservaciones ? observaciones : null)"
        >
          {{ accionMayusculas }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--  </v-col>
    </v-row> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import rulesMixin from '@/mixins/rules'
import _ from 'lodash'
export default {
  name: 'PopUpConfirmarRechazar',
  mixins: [rulesMixin],
  props: ['accion', 'mostrarIrreversible', 'mostrarObservaciones', 'elemento', 'masculino', 'plural'],
  data() {
    return {
      loading: false,
      observaciones: null,
      valid: false,
      cantidad: null
    }
  },
  computed: {
    ...mapGetters({}),
    accionCamel() {
      return _.capitalize(this.accion)
    },
    articulo() {
      if (this.plural) {
        return this.masculino ? 'los' : 'las'
      } else {
        return this.masculino ? 'el' : 'la'
      }
    },
    accionMayusculas() {
      return _.upperCase(this.accion)
    }
  },
  methods: {
    ...mapActions({})
  }
}
</script>
