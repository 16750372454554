import api from '@/api/api'
import router from '@/router/index'

export default {
  namespaced: true,
  state: {
    user: {},
    loaded: false,
    sessionChecked: false,
    checkingSession: false
  },
  mutations: {
    setUser(state, user) {
      state.user = Object.assign(state.user, user)
    },
    logout(state) {
      state.user = {}
    },
    loaded(state, payload) {
      state.loaded = payload
    },
    sessionChecked(state, payload) {
      state.sessionChecked = payload
    },
    checkingSession(state, payload) {
      state.checkingSession = payload
    }
  },
  actions: {
    restore({ commit }) {
      const userJson = localStorage.getItem('user')
      let user = null
      if (userJson !== null) {
        user = JSON.parse(userJson)
      }
      if (user !== null) {
        commit('setUser', user)
      }
    },
    async login({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        api
          .post('/login/loginCMS', {
            email,
            password
          })
          .then(response => {
            if (response.data.error == null) {
              const user = response.data
              localStorage.setItem('user', JSON.stringify(user))
              commit('setUser', user)
              commit('sessionChecked', true)
              resolve(response.data)
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async cambiarContraseña({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/login/cambiar_passwordCMS', obj)
          .then(response => {
            if (response.data.error == null) {
              resolve(response.data)
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch()
            reject(e)
          })
      })
    },
    async logout({ commit }) {
      try {
        await api.post('/login/logoutCMS')
        commit('setUser', {})
        // eslint-disable-next-line
      } catch (e) {
      } finally {
        // commit('sessionChecked', false)

        localStorage.removeItem('user', null)
        commit('logout')
        router.push({ name: 'login' })
      }
    },
    async resetPassword({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/login/reset_pwCMS', obj)
          .then(response => {
            if (response.data.error == null) {
              resolve()
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch()
            reject(e)
          })
      })
    },
    async modificarPassword({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/login/set_pwCMS', obj)
          .then(response => {
            if (response.data.error == null) {
              resolve()
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch()
            reject(e)
          })
      })
    },
    async checkSession({ commit }) {
      try {
        await api.get('/login/check_sessionCMS')
        commit('sessionChecked', true)
      } catch (e) {
        commit('logout')
        localStorage.removeItem('user')
        throw e
      }
    },
    setLoaded({ commit }, payload) {
      commit('loaded', payload)
    },
    setSessionChecked({ commit }, payload) {
      commit('sessionChecked', payload)
    },
    setCheckingSession({ commit }, payload) {
      commit('checkingSession', payload)
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    loaded(state) {
      return state.loaded
    },
    sessionChecked(state) {
      return state.sessionChecked
    },
    checkingSession(state) {
      return state.checkingSession
    },
    loggedIn(state) {
      return state.user.id != null
    }
  }
}
