function upload(formData, fileType) {
  const files = formData.getAll(Array.from(formData.keys())[0])
  const promises = files.map(x =>
    getFile(x, fileType).then(file => {
      let extension = x.name.split('.').pop()
      let nombre = x.name
      if (nombre.length > 100) {
        let longitudExtension = !extension ? 0 : extension.length
        nombre = nombre.substring(0, 100 - (longitudExtension + 1))
        if (extension) {
          nombre += '.' + extension
        }
      }
      return {
        nombre: nombre,
        mime: x.type ? x.type : fileType,
        extension: extension,
        documentoGrande: file.documentoGrande
      }
    })
  )
  return Promise.all(promises)
}

function getFile(file, fileType) {
  return new Promise((resolve, reject) => {
    if (!file.type && fileType === null) {
      reject(new Error(file.name + ' no es un fichero válido, pruebe comprimiéndolo en .zip'))
    } else if (!file.size > 10485760) {
      reject(new Error(file.name + ' demasiado grande, máximo 10MB'))
    } else {
      const fReader = new FileReader()
      fReader.onload = () => {
        resolve({
          documentoGrande: fReader.result
        })
      }
      fReader.readAsDataURL(file)
    }
  })
}

export { upload }
