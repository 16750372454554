<template>
  <v-card>
    <v-card-title class="text-h5 primary white--text justify-center">Cambiar contraseña</v-card-title>
    <v-divider></v-divider>
    <v-container pa-2 fluid>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="8">
          <v-form v-model="valid" ref="form">
            <v-text-field
              v-model="passwordActual"
              :name="passwordActual"
              :rules="[rules.required]"
              type="password"
              label="Contraseña actual"
            />
            <v-text-field
              v-model="password"
              :name="password"
              :rules="[rules.required]"
              type="password"
              label="Nueva contraseña"
            ></v-text-field>
            <v-text-field
              v-model="passwordRepetida"
              :name="passwordRepetida"
              :rules="[rules.required, rules.pwIguales(password, passwordRepetida)]"
              type="password"
              label="Repetir nueva contraseña"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-btn color="primary" text @click="$emit('cancelar')"> <v-icon>mdi-chevron-left</v-icon>Volver </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="confirmar()">
        Confirmar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import rulesMixin from '../../mixins/rules.js'
export default {
  name: 'PopUpCambioPW',
  data() {
    return {
      valid: false,
      password: null,
      passwordRepetida: null,
      passwordActual: null
    }
  },
  mixins: [rulesMixin],
  computed: {
    ...mapGetters({}),
    objParaApi() {
      return {
        pwActual: this.passwordActual,
        pwNueva: this.password,
        pwrepetida: this.passwordRepetida
      }
    }
  },
  created() {},
  methods: {
    ...mapActions({
      addAlert: 'alerts/add'
    }),
    confirmar() {
      this.$refs.form.validate()
      if (!this.valid) {
        this.addAlert({ text: 'Por favor, compruebe los campos en rojo', color: 'error' })
        return
      }
      this.$emit('confirmar', this.objParaApi)
    }
  }
}
</script>
