<template>
  <page-layout
    :breadcrumbs="[
      {
        text: 'HOME',
        disabled: false,
        exact: true,
        to: '/'
      },
      {
        text: 'NOTICIAS',
        disabled: true,
        exact: true
      }
    ]"
  >
    <v-container>
      <CabeceraYTablas :tipo="'NOTICIAS'">
        <v-data-table
          :headers="headers"
          :items="news"
          item-key="id"
          :loading="loading"
          class="elevation-1"
          :options.sync="pagination"
          :server-items-length="totalNews"
          :footer-props="{
            'items-per-page-text': 'Noticias por pagina',
            pageText: '{0}-{1} de {2}',
            'items-per-page-options': [25, 50, 100]
          }"
        >
          <template v-slot:top>
            <v-row align="end" justify="space-between" class="mx-4">
              <v-col cols="6">
                <v-text-field v-model="search" @blur="loadNews()" label="Buscar" class="mx-4"></v-text-field
              ></v-col>
              <v-col>
                <v-autocomplete
                  :items="promociones"
                  clearable
                  label="Promoción"
                  v-model="promocionSeleccionada"
                  item-text="promotion_desc"
                  item-value="promotion_code"
                ></v-autocomplete>
              </v-col>
              <v-col cols="auto" justify="space-between" align="end">
                <v-btn class="primary" @click="abrirCrear()">Nueva noticia</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.summary="{ item }">
            <td style="max-width: 500px;">{{ item.summary }}</td>
          </template>
          <template v-slot:no-data>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:no-results>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="primary--text mr-2" @click="abrirVer(item)">
              {{ 'mdi-eye' }}
            </v-icon>
            <v-icon class="primary--text mr-2" @click="abrirEditar(item)">
              {{ 'mdi-pencil' }}
            </v-icon>
            <v-icon class="primary--text iconAction" @click.stop="borrar(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </CabeceraYTablas>
    </v-container>
    <v-dialog v-if="abrirNew" v-model="abrirNew" width="80%">
      <Noticia
        :noticia="noticia"
        :ver="viendo"
        :accion="accion"
        @editar="guardarNoticia($event, false)"
        @crear="guardarNoticia($event, true)"
        @cancelar="cancelarEditar()"
      />
    </v-dialog>
    <v-dialog v-if="abrirPopUpBorrar" v-model="abrirPopUpBorrar" width="600">
      <PopUpEliminar
        @confirmar="borrarNoticia()"
        @cancelar="cancelarEditar()"
        :nombre="'Noticia'"
        :tipo="'noticia'"
        :masculino="false"
      />
    </v-dialog>
  </page-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CabeceraYTablas from '@/components/generic/CabeceraYTablas.vue'
import PopUpEliminar from '@/components/popUps/PopUpEliminar'
import Noticia from '@/pages/Noticia.vue'
export default {
  name: 'GestionNoticias',
  components: {
    CabeceraYTablas,
    PopUpEliminar,
    Noticia
  },
  props: [],
  data() {
    return {
      loading: false,
      abrirNew: false,
      search: '',
      abrirPopUpBorrar: false,
      noticiaBorrar: null,
      noticia: null,
      promocionSeleccionada: null,
      news: [],
      viendo: false,
      searchFields: ['title', 'summary'],
      totalNews: 0,
      inicializado: false,
      accion: null,
      promociones: null,
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['fecha'],
        sortDesc: [true, false],
        multiSort: true
      }
    }
  },
  async created() {
    this.loading = true
    this.promociones = await this.loadPromotions()
    await this.loadNews()
    this.loading = false
    this.inicializado = true
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    }),
    headers() {
      return [
        {
          text: 'Título',
          align: 'start',
          sortable: true,
          value: 'title'
        },
        {
          text: 'Promoción',
          align: 'start',
          sortable: true,
          value: 'promotion_desc'
        },
        {
          text: 'Resumen',
          align: 'start',
          sortable: true,
          value: 'summary'
        },
        {
          text: 'Fecha',
          align: 'start',
          sortable: true,
          value: 'fecha'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getNewsBack: 'news/getNews',
      loadPromotions: 'promotions/getPromotions',
      updateNoticia: 'news/updateNoticia',
      deleteNoticia: 'news/deleteNoticia',
      addAlert: 'alerts/add'
    }),
    borrar(item) {
      this.abrirPopUpBorrar = true
      this.noticiaBorrar = item
    },
    abrirVer(item) {
      this.accion = 'ver'
      this.abrirNew = true
      this.noticia = item
      this.viendo = true
    },
    abrirEditar(item) {
      this.accion = 'editar'
      this.abrirNew = true
      this.noticia = item
      this.viendo = false
    },
    abrirCrear() {
      this.accion = 'crear'
      this.viendo = false
      this.abrirNew = true
      this.noticia = null
    },
    cancelarEditar() {
      this.viendo = false
      this.accion = ''
      this.abrirNew = false
      this.abrirPopUpBorrar = false
      this.noticia = null
    },
    async guardarNoticia(ev, crear) {
      try {
        await this.updateNoticia({ noticia: ev })
        this.addAlert({
          text: 'La noticia se ha ' + (crear ? 'creado' : 'editado') + ' correctamente',
          color: 'success'
        })
        this.accion = ''
        this.loading = true
        await this.loadNews()
        this.abrirNew = false
      } catch (error) {
        this.loading = false
        this.abrirNew = false
        return error
      }
    },
    async borrarNoticia() {
      try {
        await this.deleteNoticia({ noticia: this.noticiaBorrar })
        this.addAlert({ text: 'La noticia se ha eliminado correctamente', color: 'success' })
        this.accion = ''
        this.loading = true
        await this.loadNews()
        this.abrirPopUpBorrar = false
        this.abrirNew = false
      } catch (error) {
        this.loading = false
        this.abrirPopUpBorrar = false
        this.abrirNew = false
        return error
      }
    },
    async loadNews() {
      this.loading = true
      const data = await this.getNewsBack({
        filtros: {
          promocion: this.promocionSeleccionada,
          search: this.search !== null ? this.search : null,
          searchFields: this.searchFields,
          ...this.pagination
        }
      })
      this.news = data.items
      this.totalNews = data.totalItems
      this.loading = false
    }
  },
  watch: {
    pagination: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadNews()
        }
      }
    },
    promocionSeleccionada: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadNews()
        }
      }
    }
  }
}
</script>
<style lang="sass">
.newSelect .v-select__selections
  max-height: 32px
  flex-flow: nowrap
  text-overflow: ellipsis
</style>
