<template>
  <div
    @click.stop="dissmiss()"
    style="cursor:pointer;position:fixed; top:0;left:0;z-index:5;width:100%; height:100vh;overflow:auto;background-color:rgba(0,0,0,0.3); justify-content:center;display:flex;align-items:center"
  >
    <div style="width:90%; background-color:white;z-index:2; max-height: 90vh; overflow-y: scroll" @click.stop="">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomDialog',
  components: {},
  data() {
    return {
      estado: ''
    }
  },
  created() {
    document.body.classList.add('abiertoPopUp')
    this.estado = this.default
  },
  props: ['dissmisseable'],
  methods: {
    dissmiss() {
      document.body.classList.remove('abiertoPopUp')
      if (this.dissmisseable) {
        this.$emit('didDismiss')
      }
    }
  },
  computed: {}
}
</script>
<style>
body.abiertoPopUp {
  height: 100vh;
  overflow: hidden;
}
</style>
