<template>
  <div>
    <template v-if="isSuccess">
      <v-img v-if="!file.id" :src="file.urlImagen" />
      <img
        v-if="file.id || file.mime.startsWith('image')"
        :src="file.id ? 'https://nuvaLiving.overtek.es/' + file.url : file.documentoGrande"
        class="image-img"
        :style="{ 'max-width': '800px', 'max-height': '800px' }"
      />
    </template>
    <template v-if="isSaving"><v-progress-circular indeterminate color="primary"></v-progress-circular></template>
    <template v-if="isFailed"
      ><span
        style="margin-left:5px;margin-right:5px;padding:5px;font-size:14px;"
        :title="file.error"
        class="arbolEllipsis error--text"
        >ERROR: {{ file.error }}</span
      ></template
    >
    <div :class="[readonly ? 'image-remove-visible' : 'image-remove']" :title="isFailed ? file.error : file.nombre">
      <v-btn
        :class="[readonly ? 'image-download-button-visible' : 'image-download-button']"
        v-if="file.id"
        color="primary"
        icon
        @click.stop
        :href="$image(file)"
        target="_blank"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn v-if="!readonly" color="primary" icon class="image-remove-button" @click.stop="$emit('remove')">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    file: {
      required: true
    },
    readonly: {
      default: false
    }
  },
  computed: {
    isSaving: function() {
      return this.file.status === 'SAVING'
    },
    isSuccess: function() {
      return this.file.id || this.file.status === 'SUCCESS'
    },
    isFailed: function() {
      return this.file.status === 'FAILED'
    }
  },
  beforeDestroy() {}
}
</script>

<style lang="sass">
.image-list-item .image-remove
  z-index: 2
  opacity: 0
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  transition: opacity
  &:hover
    opacity: 1

.image-list-item .image-remove-visible
  z-index: 2
  opacity: 1
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.image-list-item.hide-remove .image-remove:hover,
.image-list-item.sortable-chosen .image-remove:hover
  opacity: 0

.image-list-item .image-remove .image-remove-button
  position: absolute
  top: 0
  right: 0

.image-list-item .image-remove .image-download-button
  position: absolute
  top: 0
  right: 40px

.image-list-item .image-remove-visible .image-download-button-visible
  position: absolute
  opacity: 1
  top: 0
  right: 0px

.image-list-item .image-img
  width: 100%
  height: 100%
  object-fit: contain
</style>
