<template>
  <page-layout
    :breadcrumbs="[
      {
        text: 'HOME',
        disabled: false,
        exact: true,
        to: '/'
      },
      {
        text: 'CASH BACK',
        disabled: true,
        exact: true
      }
    ]"
  >
    <v-container>
      <CabeceraYTablas :tipo="'CASH BACK'">
        <v-data-table
          :headers="headers"
          :items="nuvacoins"
          :options.sync="pagination"
          item-key="id"
          :loading="loading"
          class="elevation-1"
          :server-items-length="totalNuvaCoins"
          :footer-props="{
            'items-per-page-text': 'NuvaCoins por pagina',
            pageText: '{0}-{1} de {2}',
            'items-per-page-options': [25, 50, 100]
          }"
        >
          <template v-slot:item.day="{ item }">
            <span>{{ item.day | formatDate }}</span>
          </template>
          <template v-slot:item.translatedState="{ item }">
            <span :class="[item.translatedState]">{{ item.translatedState }}</span>
          </template>
          <template v-slot:item.comment="{ item }">
            <span class="nowrap">{{ item.comment }}</span>
          </template>
          <template v-slot:item.price="{ item }">
            <span class="nowrap">{{ item.price | currency }}</span>
          </template>
          <template v-slot:no-data>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:no-results>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="primary--text mr-2" @click="abrirVer(item)">
              {{ 'mdi-eye' }}
            </v-icon>
            <v-icon
              class="primary--text mr-2"
              @click="mostrarValidar(item)"
              :loading="loading"
              :disabled="item.state != 10"
            >
              {{ 'mdi-check' }}
            </v-icon>
            <v-icon
              class="primary--text mr-2"
              @click="mostrarPopUp('rechazar', item)"
              :loading="loading"
              :disabled="item.state != 10"
            >
              {{ 'mdi-close' }}
            </v-icon>
          </template>
        </v-data-table>
      </CabeceraYTablas>
    </v-container>
    <v-dialog v-if="abrirGestion" v-model="abrirGestion" width="60%">
      <NuvaCoins :gestion="gestion" @cancelar="abrirGestion = false" :accion="'ver'" />
    </v-dialog>
    <v-dialog v-if="validarOpen" v-model="validarOpen" width="60%">
      <NuvaCoinsValidate :gestion="gestion" @cancelar="validarOpen = false" @confirmar="validarNuvaCoins($event)" />
    </v-dialog>
    <v-dialog v-if="abrirPopUp" v-model="abrirPopUp" width="600">
      <PopUpConfirmarRechazar
        @confirmar="rechazarNuvaCoins($event)"
        @cancelar="cancelar"
        :accion="tipo"
        :masculino="true"
        :plural="true"
        :elemento="'Nuva coins'"
        :mostrarIrreversible="true"
        :mostrarObservaciones="false"
      />
    </v-dialog>
  </page-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CabeceraYTablas from '@/components/generic/CabeceraYTablas.vue'
import PopUpConfirmarRechazar from '@/components/popUps/PopUpConfirmarRechazar'
import NuvaCoins from '@/pages/NuvaCoins.vue'
import NuvaCoinsValidate from '@/pages/NuvaCoinsValidate.vue'
export default {
  name: 'GestionNuvaCoins',
  components: {
    CabeceraYTablas,
    PopUpConfirmarRechazar,
    NuvaCoins,
    NuvaCoinsValidate
  },
  props: [],
  data() {
    return {
      loading: false,
      abrirGestion: false,
      tipo: null,
      abrirPopUp: false,
      gestion: null,
      nuvacoins: [],
      totalNuvaCoins: 0,
      inicializado: false,
      validarOpen: false,
      serviciosValorados: null,
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['day'],
        sortDesc: [true],
        multiSort: true
      }
    }
  },
  async created() {
    this.loading = true
    await this.loadNuvaCoins()

    this.loading = false
    this.inicializado = true
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    }),
    headers() {
      return [
        {
          text: 'Fecha',
          align: 'start',
          sortable: true,
          value: 'day'
        },
        {
          text: 'Producto',
          align: 'start',
          value: 'product.title'
        },
        {
          text: 'Usuario',
          align: 'start',
          value: 'user.mixName'
        },
        {
          text: 'Importe',
          align: 'center',
          value: 'price'
        },
        {
          text: 'Estado',
          align: 'center',
          value: 'translatedState'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          width: 200,
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getNuvaCoins: 'nuvacoins/getNuvaCoins',
      validarNuvaCoinsBack: 'nuvacoins/validarNuvaCoins',
      rechazarNuvaCoinsBack: 'nuvacoins/rechazarNuvaCoins',
      addAlert: 'alerts/add'
    }),
    async validarNuvaCoins(cantidad) {
      this.loading = true
      this.abrirPopUp = false
      await this.validarNuvaCoinsBack({ id: this.gestion.id, cantidad: cantidad })
      this.loading = false
      this.gestion = null
      this.validarOpen = false
      this.addAlert({ text: 'Valoración validada con éxito', color: 'success' })
      await this.loadNuvaCoins()
    },
    mostrarValidar(item) {
      this.gestion = item
      this.validarOpen = true
    },
    async rechazarNuvaCoins() {
      this.loading = true
      this.abrirPopUp = false
      await this.rechazarNuvaCoinsBack({ id: this.gestion.id })
      this.loading = false
      this.addAlert({ text: 'Valoración rechazada con éxito', color: 'success' })
      this.gestion = null
      await this.loadNuvaCoins()
    },
    abrirVer(item) {
      this.abrirGestion = true
      this.gestion = item
    },
    async loadNuvaCoins() {
      this.loading = true
      const data = await this.getNuvaCoins({
        filtros: {
          ...this.pagination
        }
      })
      this.nuvacoins = data.items
      this.totalNuvaCoins = data.totalItems
      this.loading = false
    },
    mostrarPopUp(tipo, servicio) {
      this.tipo = tipo
      this.gestion = servicio
      this.abrirPopUp = true
    },
    cancelar() {
      this.abrirPopUp = false
      this.gestion = null
    }
  },
  watch: {
    pagination: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadNuvaCoins()
        }
      }
    }
  }
}
</script>
<style lang="sass"></style>
<style scoped>
.Aprobada {
  color: rgb(58, 87, 2);
}

.Pendiente {
  color: rgb(148, 86, 5);
}

.Rechazada {
  color: rgb(202, 3, 3);
}
</style>
