<template>
  <page-layout
    :breadcrumbs="[
      {
        text: 'HOME',
        disabled: false,
        exact: true,
        to: '/'
      },
      {
        text: 'SERVICIOS',
        disabled: true,
        exact: true
      }
    ]"
  >
    <v-container>
      <CabeceraYTablas :tipo="'SERVICIOS'">
        <v-data-table
          :headers="headers"
          :items="servicios"
          :options.sync="pagination"
          item-key="id"
          :loading="loading"
          class="elevation-1"
          :server-items-length="totalServicios"
          :footer-props="{
            'items-per-page-text': 'Servicios por pagina',
            pageText: '{0}-{1} de {2}',
            'items-per-page-options': [25, 50, 100]
          }"
        >
          <template v-slot:top>
            <v-row align="center" justify="space-between" class="mx-4">
              <v-col cols="5" max class="noPadding">
                <v-select
                  class="newSelect"
                  :items="serviciosElegibles"
                  label="Servicios"
                  item-text="name"
                  :disabled="loading"
                  @blur="loadServicios()"
                  multiple
                  item-value="id"
                  v-model="serviciosFiltro"
                ></v-select
              ></v-col>
              <v-col cols="6" style="height: 100%;" class="noPadding">
                <v-autocomplete
                  :items="promociones"
                  clearable
                  label="Promoción"
                  v-model="promocionSeleccionada"
                  item-text="promotion_desc"
                  item-value="promotion_code"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" justify="space-between" class="mx-4">
              <v-col cols="5" class="noPadding">
                <v-row class="flexEnd" no-gutters>
                  <v-menu v-model="openFechaDesdeServicio" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        text
                        class="inputTxt"
                        v-model="fechaServicioDesdeFormateada"
                        :append-icon="fechaServicioDesde != null ? 'mdi-close' : ''"
                        @click:append="fechaServicioDesde = null"
                        readonly
                        label="Fecha servicio desde"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="fechaServicioDesde" locale="esp" @input="openFechaDesdeServicio = false">
                    </v-date-picker>
                  </v-menu>
                </v-row>
              </v-col>
              <v-col cols="6" class="noPadding">
                <v-row class="flexEnd" no-gutters>
                  <v-menu v-model="openFechaHastaServicio" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        text
                        class="inputTxt"
                        v-model="fechaServicioHastaFormateada"
                        readonly
                        label="Fecha servicio hasta"
                        v-bind="attrs"
                        :append-icon="fechaServicioHasta != null ? 'mdi-close' : ''"
                        @click:append="fechaServicioHasta = null"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaServicioHasta"
                      @input="openFechaHastaServicio = false"
                      locale="esp"
                      :min="minDateServicio"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" justify="space-between" class="mx-4">
              <v-col cols="5" class="noPadding">
                <v-row class="flexEnd" no-gutters>
                  <v-menu v-model="openFechaDesdeSolicitud" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        text
                        class="inputTxt"
                        v-model="fechaSolicitudDesdeFormateada"
                        :append-icon="fechaSolicitudDesde != null ? 'mdi-close' : ''"
                        @click:append="fechaSolicitudDesde = null"
                        readonly
                        label="Fecha solicitud desde"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="fechaSolicitudDesde" locale="esp" @input="openFechaDesdeSolicitud = false">
                    </v-date-picker>
                  </v-menu>
                </v-row>
              </v-col>
              <v-col cols="6" class="noPadding">
                <v-row class="flexEnd" no-gutters>
                  <v-menu v-model="openFechaHastaSolicitud" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        text
                        class="inputTxt"
                        v-model="fechaSolicitudHastaFormateada"
                        readonly
                        label="Fecha solicitud hasta"
                        v-bind="attrs"
                        :append-icon="fechaSolicitudHasta != null ? 'mdi-close' : ''"
                        @click:append="fechaSolicitudHasta = null"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaSolicitudHasta"
                      @input="openFechaHastaSolicitud = false"
                      locale="esp"
                      :min="minDateSolicitud"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" justify="space-between" class="mx-4 mb-2">
              <v-btn class="primary font-weight-bold " :href="generarServicioConFiltros()">
                {{ 'Descargar servicios' }}
              </v-btn>
            </v-row>
          </template>
          <template v-slot:item.translatedState="{ item }">
            <span :class="[item.translatedState]">{{ item.translatedState }}</span>
          </template>
          <template v-slot:item.duracion="{ item }">
            <span>{{ item.duracion + ' m' }}</span>
          </template>
          <template v-slot:no-data>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:no-results>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <td class="acciones">
              <v-icon class="primary--text mr-2" @click="abrirVer(item)">
                {{ 'mdi-eye' }}
              </v-icon>
              <v-icon
                class="primary--text mr-2"
                @click="mostrarPopUp('confirmar', item)"
                :loading="loading"
                :disabled="item.state != 10"
              >
                {{ 'mdi-check' }}
              </v-icon>
              <v-icon
                class="primary--text mr-2"
                @click="mostrarPopUp('rechazar', item)"
                :loading="loading"
                :disabled="item.state != 10"
              >
                {{ 'mdi-close' }}
              </v-icon>
            </td>
          </template>
          <template v-solt:footer> </template>
        </v-data-table>
      </CabeceraYTablas>
    </v-container>
    <v-dialog v-if="abrirServicio" v-model="abrirServicio" width="60%">
      <Servicio :reserva="reserva" @cancelar="abrirServicio = false" />
    </v-dialog>
    <v-dialog v-if="abrirPopUp" v-model="abrirPopUp" width="600">
      <PopUpConfirmarRechazar
        @confirmar="tipo === 'confirmar' ? validarServicio($event) : rechazarServicio($event)"
        @cancelar="cancelar"
        :accion="tipo"
        :elemento="'reserva'"
        :mostrarIrreversible="true"
        :mostrarObservaciones="true"
      />
    </v-dialog>
  </page-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CabeceraYTablas from '@/components/generic/CabeceraYTablas.vue'
import PopUpConfirmarRechazar from '@/components/popUps/PopUpConfirmarRechazar'
import Servicio from '@/pages/Servicio.vue'
import moment from 'moment'
export default {
  name: 'GestionServicios',
  components: {
    CabeceraYTablas,
    PopUpConfirmarRechazar,
    Servicio
  },
  props: [],
  data() {
    return {
      loading: false,
      abrirServicio: false,
      minDateServicio: null,
      minDateSolicitud: null,
      promocionSeleccionada: null,
      openFechaDesdeServicio: false,
      openFechaDesdeSolicitud: false,
      openFechaHastaServicio: false,
      openFechaHastaSolicitud: false,
      tipo: null,
      abrirPopUp: false,
      promociones: null,
      reserva: null,
      servicios: [],
      serviciosElegibles: [],
      serviciosFiltro: [],
      totalServicios: 0,
      inicializado: false,
      fechaServicioDesde: null,
      fechaSolicitudDesde: null,
      fechaServicioHasta: null,
      fechaSolicitudHasta: null,
      pagination: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['fecha'],
        sortDesc: [true],
        multiSort: true
      }
    }
  },
  async created() {
    this.loading = true
    this.promociones = await this.loadPromotions()
    const data = await this.getServiciosElegiblesBack()
    this.serviciosElegibles = data
    this.serviciosFiltro = []
    await this.loadServicios()
    this.loading = false
    this.inicializado = true
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    }),
    fechaServicioDesdeFormateada() {
      if (this.fechaServicioDesde == null) {
        return null
      } else {
        return moment(this.fechaServicioDesde).format('DD-MM-YYYY')
      }
    },
    fechaSolicitudDesdeFormateada() {
      if (this.fechaSolicitudDesde == null) {
        return null
      } else {
        return moment(this.fechaSolicitudDesde).format('DD-MM-YYYY')
      }
    },
    fechaServicioHastaFormateada() {
      if (this.fechaServicioHasta == null) {
        return null
      } else {
        return moment(this.fechaServicioHasta).format('DD-MM-YYYY')
      }
    },
    fechaSolicitudHastaFormateada() {
      if (this.fechaSolicitudHasta == null) {
        return null
      } else {
        return moment(this.fechaSolicitudHasta).format('DD-MM-YYYY')
      }
    },
    headers() {
      return [
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'servicio.name'
        },
        {
          text: 'Promoción',
          align: 'start',
          sortable: true,
          value: 'promotion_desc'
        },
        {
          text: 'Fecha del servicio',
          align: 'start',
          sortable: true,
          value: 'fecha'
        },

        {
          text: 'Hora inicio',
          align: 'start',
          value: 'horaInicio'
        },
        {
          text: 'Duración',
          align: 'start',
          value: 'duracion'
        },
        {
          text: 'Estado',
          align: 'start',
          value: 'translatedState'
        },
        {
          text: 'Usuario',
          align: 'start',
          value: 'user.mixName'
        },
        {
          text: 'Fecha de la solicitud',
          align: 'fechaPago',
          sortable: true,
          value: 'fechaPago'
        },
        {
          text: 'Precio',
          align: 'precio',
          sortable: true,
          value: 'precio'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getServicios: 'servicios/getServicios',
      loadPromotions: 'promotions/getPromotions',
      getServiciosElegiblesBack: 'servicios/getServiciosTipo',
      validarServicioBack: 'servicios/validarServicio',
      rechazarServicioBack: 'servicios/rechazarServicio',
      addAlert: 'alerts/add'
    }),
    generarServicioConFiltros() {
      let filtros = {
        serviciosFiltro: this.serviciosFiltro,
        promocion: this.promocionSeleccionada,
        fechaServicioDesde: this.fechaServicioDesdeFormateada
          ? moment(this.fechaServicioDesdeFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null,
        fechaServicioHasta: this.fechaServicioHastaFormateada
          ? moment(this.fechaServicioHastaFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null,
        fechaSolicitudDesde: this.fechaSolicitudDesdeFormateada
          ? moment(this.fechaSolicitudDesdeFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null,
        fechaSolicitudHasta: this.fechaSolicitudHastaFormateada
          ? moment(this.fechaSolicitudHastaFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null
      }
      return this.$descargarReservas(filtros)
    },
    abrirVer(item) {
      this.abrirServicio = true
      this.reserva = item
    },
    async loadServicios() {
      this.loading = true
      const data = await this.getServicios({
        filtros: {
          serviciosFiltro: this.serviciosFiltro,
          promocion: this.promocionSeleccionada,
          fechaServicioDesde: this.fechaServicioDesdeFormateada
            ? moment(this.fechaServicioDesdeFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
          fechaServicioHasta: this.fechaServicioHastaFormateada
            ? moment(this.fechaServicioHastaFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
          fechaSolicitudDesde: this.fechaSolicitudDesdeFormateada
            ? moment(this.fechaSolicitudDesdeFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
          fechaSolicitudHasta: this.fechaSolicitudHastaFormateada
            ? moment(this.fechaSolicitudHastaFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
          ...this.pagination
        }
      })
      this.servicios = data.items
      this.totalServicios = data.totalItems
      this.loading = false
    },
    mostrarPopUp(tipo, servicio) {
      this.tipo = tipo
      this.reserva = servicio
      this.abrirPopUp = true
    },
    cancelar() {
      this.abrirPopUp = false
      this.reserva = null
    },
    async validarServicio(observaciones) {
      this.loading = true
      this.abrirPopUp = false
      await this.validarServicioBack({ id: this.reserva.id, observaciones })
      this.loading = false
      this.reserva = null
      this.addAlert({ text: 'Servicio validado con éxito', color: 'success' })
      await this.loadServicios()
    },
    async rechazarServicio(observaciones) {
      this.loading = true
      this.abrirPopUp = false
      await this.rechazarServicioBack({ id: this.reserva.id, observaciones })
      this.loading = false
      this.addAlert({ text: 'Servicio cancelado con éxito', color: 'success' })
      this.reserva = null
      await this.loadServicios()
    }
  },
  watch: {
    promocionSeleccionada: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadServicios()
        }
      }
    },
    fechaServicioDesdeFormateada: {
      deep: true,
      async handler() {
        this.minDateServicio = this.fechaServicioDesdeFormateada
          ? moment(this.fechaServicioDesdeFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null
        this.openFechaDesdeServicio = false
        if (this.inicializado) {
          await this.loadServicios()
        }
      }
    },
    fechaSolicitudDesdeFormateada: {
      deep: true,
      async handler() {
        this.minDateSolicitud = this.fechaSolicitudDesdeFormateada
          ? moment(this.fechaSolicitudDesdeFormateada, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null
        this.openFechaDesdeSolicitud = false
        if (this.inicializado) {
          await this.loadServicios()
        }
      }
    },
    fechaServicioHastaFormateada: {
      deep: true,
      async handler() {
        this.openFechaHastaServicio = false
        if (this.inicializado) {
          await this.loadServicios()
        }
      }
    },
    fechaSolicitudHastaFormateada: {
      deep: true,
      async handler() {
        this.openFechaHastaSolicitud = false
        if (this.inicializado) {
          await this.loadServicios()
        }
      }
    },
    pagination: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadServicios()
        }
      }
    }
  }
}
</script>
<style lang="sass">
.newSelect .v-select__selections
  max-height: 32px
  flex-flow: nowrap
  text-overflow: ellipsis

.v-menu__content.menuable__content__active
  min-width: auto !important
</style>
<style scoped>
.Validado {
  color: rgb(58, 87, 2);
}

.Pendiente {
  color: rgb(148, 86, 5);
}

.Rechazado {
  color: rgb(202, 3, 3);
}
</style>
