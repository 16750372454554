<template>
  <div>
    <PopUpEditar
      nombre="PROMOCION"
      :tipo="'promoción'"
      :accion="accion"
      :masculino="false"
      @enviarEditar="enviarEditar()"
      @enviarCrear="enviarCrear()"
      @cancelar="
        $emit('cancelar')
        resetValues()
      "
    >
      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col cols="12">
            <v-row justify="start" align="center" dense>
              <v-col cols="auto">
                <v-select
                  :items="servicios"
                  label="Servicio"
                  item-text="name"
                  item-value="id"
                  :disabled="ver"
                  :rules="[rules.required]"
                  v-model="promocionEditar.service_id"
                ></v-select>
              </v-col>
              <v-col style="height: 100%;">
                <v-autocomplete
                  :items="promociones"
                  label="Promoción"
                  :readonly="ver"
                  v-model="promocionSeleccionada"
                  :rules="[rules.required]"
                  return-object
                  item-text="promotion_desc"
                  item-value="promotion_code"
                ></v-autocomplete>
              </v-col>
              <v-col style="margin-top:13px">
                <v-text-field
                  style="margin-right: 10px;"
                  dense
                  type="number"
                  :readonly="ver"
                  label="Porcentaje extra de nuva coins"
                  :rules="[rules.required, rules.mayorCero, rules.integerNumber]"
                  suffix="%"
                  v-model="promocionEditar.percentage_extra"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </PopUpEditar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PopUpEditar from '@/components/generic/PopUpEditar.vue'
import rulesMixin from '@/mixins/rules'
export default {
  name: 'Promocion',
  mixins: [rulesMixin],
  components: {
    PopUpEditar
  },
  props: ['promocion', 'ver', 'accion'],
  data() {
    return {
      promocionEditar: {
        service_id: null,
        percentage_extra: null,
        promotion_desc: null,
        promotion_code: null
      },
      promocionSeleccionada: null,
      promociones: null,
      servicios: null,
      valid: false,
      modal: false
    }
  },
  async created() {
    this.promociones = await this.loadPromotions()
    const data = await this.getServicios()
    this.servicios = data

    if (this.promocion != null) {
      this.promocionEditar = JSON.parse(JSON.stringify(this.promocion))
      this.promocionSeleccionada = this.promocion
      this.servicios.push(this.promocion.servicio)
    }
  },
  computed: {
    ...mapGetters({}),
    cambiado() {
      return this.promocion != null && JSON.stringify(this.promocion) != JSON.stringify(this.promocionEditar)
    }
  },
  methods: {
    ...mapActions({
      addAlert: 'alerts/add',
      loadPromotions: 'promotions/getPromotions',
      getServicios: 'servicios/getServiciosTipo'
    }),
    resetValues() {
      this.promocionEditar.percentage_extra = null
      this.promocionEditar.service_id = null
    },
    enviarEditar() {
      this.$refs.form.validate()
      if (!this.valid) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      this.$emit('editar', this.promocionEditar)
    },
    enviarCrear() {
      this.$refs.form.validate()
      if (!this.valid) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      this.$emit('crear', this.promocionEditar)
    }
  },
  watch: {
    promocionSeleccionada(newVal) {
      console.log(newVal)
      this.promocionEditar.promotion_code = newVal.promotion_code
      this.promocionEditar.promotion_desc = newVal.promotion_desc
    }
  }
}
</script>
<style>
.noMostrar .editr--toolbar {
  display: none;
}
.noMostrar .editr {
  border: none;
}
</style>
