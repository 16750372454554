import api from '@/api/api'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getRatings({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .get('/cms/ratings/get_list_ratings', {
            params: obj
          })
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async getFakeUsers({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .get('/cms/ratings/get_fake_users', {
            params: obj
          })
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async crearValoracionFake({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/cms/ratings/create_fake_rating', obj)
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async validarValoracion({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/cms/ratings/validate_rating', obj)
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async rechazarValoracion({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/cms/ratings/cancel_rating', obj)
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  },
  getters: {}
}
