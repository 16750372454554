import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import wysiwyg from 'vue-wysiwyg'

Vue.use(wysiwyg, {
  hideModules: {
    code: true,
    image: true,
    table: true,
    separator: true,
    justifyLeft: true,
    justifyCenter: true,
    justifyRight: true,
    headings: true,
    link: true,
    orderedList: true,
    unorderedList: true
  }
}) // config is optional. more below

Vue.use(Vuetify)

import es from 'vuetify/lib/locale/es.js'

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#2C3B51',
        secondary: 'A0522D'
      },
      dark: {
        primary: '#2C3B51',
        secondary: 'A0522D'
      }
    },
    options: {
      customProperties: true
    }
  }
})
