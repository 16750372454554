<template>
  <page-layout
    :breadcrumbs="[
      {
        text: 'HOME',
        disabled: false,
        exact: true,
        to: '/'
      },
      {
        text: 'VALORACIONES',
        disabled: true,
        exact: true
      }
    ]"
  >
    <v-container>
      <v-row>
        <v-col cols="4" v-for="servicio in serviciosMedia" :key="servicio.id">
          <v-card class="mx-auto " elevation-0>
            <v-img :src="servicio.image" height="200px"></v-img>
            <v-row justify="space-between" class="ma-2" align="center">
              <v-col class=" v-card__title">
                {{ servicio.titulo }}
              </v-col>
              <v-col cols="auto" v-if="servicio.notaMedia">
                <v-row>
                  <v-img src="@/assets/images/star.png" primary width="20" class="mr-2"></v-img>
                  {{ parseFloat(servicio.notaMedia).toFixed(2) }}
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="space-between" class="ma-2" align="center">
              <v-col> <span class="negrita"> Valoraciones: </span>{{ servicio.numeroValoraciones }} </v-col>
              <v-col> <span class="negrita"> Comentarios: </span>{{ servicio.numeroComentarios }} </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <CabeceraYTablas :tipo="'VALORACIONES'">
        <v-data-table
          :headers="headers"
          :items="valoraciones"
          :options.sync="pagination"
          item-key="id"
          :loading="loading"
          class="elevation-1"
          :server-items-length="totalValoraciones"
          :footer-props="{
            'items-per-page-text': 'Valoraciones por pagina',
            pageText: '{0}-{1} de {2}',
            'items-per-page-options': [25, 50, 100]
          }"
        >
          <template v-slot:top>
            <v-row align="center" justify="space-between" class="mx-4">
              <v-col cols="8" max>
                <v-select
                  class="newSelect"
                  :items="serviciosElegibles"
                  label="Servicios"
                  item-text="name"
                  :disabled="loading"
                  @blur="inicializado ? loadValoraciones() : ''"
                  multiple
                  item-value="id"
                  v-model="serviciosFiltro"
                ></v-select
              ></v-col>
              <v-col cols="auto" justify="end" align="center">
                <v-btn class="primary" @click="abrirCrear()">Nueva valoración</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.translatedState="{ item }">
            <span :class="[item.translatedState]">{{ item.translatedState }}</span>
          </template>
          <template v-slot:item.comment="{ item }">
            <span class="nowrap">{{ item.comment }}</span>
          </template>
          <template v-slot:no-data>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:no-results>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:item.actions="{ item }" style="min-width:100px;">
            <v-icon
              class="primary--text mr-2"
              @click="abrirVer(item)"
              :disabled="item.comment == null || item.comment == ''"
            >
              {{ 'mdi-eye' }}
            </v-icon>
            <v-icon
              class="primary--text mr-2"
              @click="mostrarPopUp('validar', item)"
              :loading="loading"
              :disabled="item.state != 10"
            >
              {{ 'mdi-check' }}
            </v-icon>
            <v-icon
              class="primary--text mr-2"
              @click="mostrarPopUp('rechazar', item)"
              :loading="loading"
              :disabled="item.state != 10"
            >
              {{ 'mdi-close' }}
            </v-icon>
          </template>
        </v-data-table>
      </CabeceraYTablas>
    </v-container>
    <v-dialog v-if="abrirValoracion" v-model="abrirValoracion" width="600">
      <Valoracion
        :valoracion="valoracion"
        @cancelar="abrirValoracion = false"
        :accion="accion"
        @crear="guardarValoracion($event, true)"
      />
    </v-dialog>
    <v-dialog v-if="abrirPopUp" v-model="abrirPopUp" width="600">
      <PopUpConfirmarRechazar
        @confirmar="tipo === 'validar' ? validarValoracion($event) : rechazarValoracion($event)"
        @cancelar="cancelar"
        :accion="tipo"
        :elemento="'valoración'"
        :mostrarIrreversible="true"
        :mostrarObservaciones="false"
      />
    </v-dialog>
  </page-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CabeceraYTablas from '@/components/generic/CabeceraYTablas.vue'
import _ from 'lodash'
import PopUpConfirmarRechazar from '@/components/popUps/PopUpConfirmarRechazar'
import Valoracion from '@/pages/Valoracion.vue'
export default {
  name: 'GestionValoraciones',
  components: {
    CabeceraYTablas,
    PopUpConfirmarRechazar,
    Valoracion
  },
  props: [],
  data() {
    return {
      loading: false,
      abrirValoracion: false,
      tipo: null,
      abrirPopUp: false,
      serviciosElegibles: [],
      serviciosFiltro: [],
      accion: null,
      valoracion: null,
      valoraciones: [],
      totalValoraciones: 0,
      serviciosMedia: [
        {
          id: 1,
          titulo: 'Tareas del hogar',
          image: require('@/assets/images/Limpieza.png')
        },
        {
          id: 2,
          titulo: 'Car-washing',
          image: require('@/assets/images/Car.png')
        },
        {
          id: 3,
          titulo: 'Paseador de mascotas',
          image: require('@/assets/images/Mascotas.png')
        }
      ],
      inicializado: false,
      serviciosValorados: null,
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['fecha'],
        sortDesc: [true],
        multiSort: true
      }
    }
  },
  async created() {
    this.loading = true
    const dataser = await this.getServiciosElegiblesBack()
    this.serviciosElegibles = dataser
    const data = await this.getServicios()
    this.serviciosValorados = data
    await this.loadValoraciones()

    this.loading = false
    this.inicializado = true
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    }),
    headers() {
      return [
        {
          text: 'Fecha',
          align: 'start',
          sortable: true,
          value: 'fecha'
        },
        {
          text: 'Servicio',
          align: 'start',
          value: 'reservation.servicio.name'
        },
        {
          text: 'Usuario',
          align: 'start',
          value: 'user.mixName'
        },
        {
          text: 'Promoción',
          align: 'center',
          value: 'promotion'
        },
        {
          text: 'Puntuación',
          align: 'center',
          value: 'stars'
        },
        {
          text: 'Comentario',
          align: 'start',
          value: 'comment'
        },
        {
          text: 'Estado',
          align: 'center',
          value: 'translatedState'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          width: 150,
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getValoraciones: 'ratings/getRatings',
      validarValoracionBack: 'ratings/validarValoracion',
      crearValoracionFake: 'ratings/crearValoracionFake',
      getServiciosElegiblesBack: 'servicios/getServiciosTipo',
      getServicios: 'servicios/getServiciosRating',
      rechazarValoracionBack: 'ratings/rechazarValoracion',
      addAlert: 'alerts/add'
    }),
    abrirCrear() {
      this.accion = 'crear'
      this.abrirValoracion = true
      this.valoracion = {
        comment: null,
        user_id: null,
        service_id: null,
        stars: null
      }
    },
    async validarValoracion() {
      this.loading = true
      this.abrirPopUp = false
      await this.validarValoracionBack({ id: this.valoracion.id })
      this.loading = false
      this.valoracion = null
      this.addAlert({ text: 'Valoración validada con éxito', color: 'success' })
      await this.loadValoraciones()
    },
    async rechazarValoracion() {
      this.loading = true
      this.abrirPopUp = false
      await this.rechazarValoracionBack({ id: this.valoracion.id })
      this.loading = false
      this.addAlert({ text: 'Valoración rechazada con éxito', color: 'success' })
      this.valoracion = null
      await this.loadValoraciones()
    },
    async guardarValoracion(ev, crear) {
      try {
        await this.crearValoracionFake({ valoracion: ev })
        this.addAlert({
          text: 'La valoración se ha ' + (crear ? 'creado' : 'editado') + ' correctamente',
          color: 'success'
        })
        this.loading = true
        await this.loadValoraciones()
      } catch (error) {
        // this.addAlert({ text: error, color: 'error' })
      } finally {
        this.accion = ''
        this.loading = false
        this.abrirValoracion = false
      }
    },
    abrirVer(item) {
      this.abrirValoracion = true
      this.valoracion = item
      this.accion = 'ver'
    },
    async loadValoraciones() {
      this.loading = true
      const data = await this.getValoraciones({
        servicios: this.serviciosFiltro,
        filtros: {
          ...this.pagination
        }
      })
      this.valoraciones = data.items
      this.serviciosMedia = this.serviciosMedia.map(x => {
        let misRatings = this.serviciosValorados.filter(y => x.id == y.id)[0]
        return {
          ...x,

          notaMedia: misRatings.ratings ? _.sumBy(misRatings.ratings, 'stars') / misRatings.ratings.length : null,
          numeroValoraciones: misRatings.ratings ? misRatings.ratings.length : null,
          numeroComentarios: misRatings.ratings
            ? misRatings.ratings.filter(y => y.comment != null)
              ? misRatings.ratings.filter(y => y.comment != null).length
              : null
            : null
        }
      })
      this.totalValoraciones = data.totalItems
      this.loading = false
    },
    mostrarPopUp(tipo, servicio) {
      this.tipo = tipo
      this.valoracion = servicio
      this.abrirPopUp = true
    },
    cancelar() {
      this.abrirPopUp = false
      this.valoracion = null
    }
  },
  watch: {
    pagination: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadValoraciones()
        }
      }
    }
  }
}
</script>
<style lang="sass">
.nowrap
  min-width: 250px
  max-width:300px
  overflow: hidden
  white-space: nowrap
  display: inline-block
  text-overflow: ellipsis
</style>
<style scoped>
.Aprobada {
  color: rgb(58, 87, 2);
}

.Pendiente {
  color: rgb(148, 86, 5);
}

.Rechazada {
  color: rgb(202, 3, 3);
}
</style>
