export default {
  data() {
    return {
      rules: {
        required: v =>
          (v !== null && v !== undefined && v !== '' && (!Array.isArray(v) || v.length > 0)) || 'Obligatorio',
        email: v =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Formato email incorrecto',
        pwIguales: n => v => v == null || n == null || v === n || 'Las contraseñas no son iguales',
        min: n => v => v == null || v.length >= n || 'Min. ' + n + ' caracteres',
        max: n => v => v == null || v.length <= n || 'Max. ' + n + ' caracteres',
        maxNumber: n => v => v == null || v.toString().length <= n || 'Max. ' + n + ' caracteres',
        floatNumber: v => v == null || /^\d((\d{0,2}(\.\d{3})*)+(,\d+)?)$/.test(v) || 'Este campo es númerico',
        mayorCero: v => v > 0 || 'El valor debe ser mayor de 0',
        integerNumber: v => v == null || /^0*(?:[1-9]|[1-9]\d\d*)$/.test(v) || 'Formato incorrecto',
        metodoPago000: v =>
          v != '000' ||
          'El código 000 para los metodos de pago esta reservado para la diferencia de caja. Por favor introduzca otro código'
      }
    }
  }
}
