<template>
  <div>
    <PopUpEditar
      nombre="VALORACIÓN"
      :accion="accion"
      :tipo="'Valoración'"
      :masculino="false"
      @enviarEditar="enviarEditar()"
      @enviarCrear="enviarCrear()"
      @cancelar="$emit('cancelar')"
    >
      <v-row v-if="accion == 'ver'">
        <v-col cols="12">
          <span class="negrita">Comentario: </span>
          <span>{{ valoracion.comment }}</span>
        </v-col>
      </v-row>
      <v-form v-model="valid" ref="form" v-else>
        <v-row>
          <v-col cols="6">
            <v-select
              :items="nombresFicticios"
              label="Usuario"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
              v-model="valoracion.user_id"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="servicios"
              label="Servicio"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
              v-model="valoracion.service_id"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="puntuacion"
              label="Estrellas"
              :rules="[rules.required]"
              v-model="valoracion.stars"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea
              auto-grow
              name="input-7-1"
              variant="filled"
              label="Comentario"
              :rules="[rules.required, rules.max(1000)]"
              v-model="valoracion.comment"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </PopUpEditar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PopUpEditar from '@/components/generic/PopUpEditar.vue'
import rulesMixin from '@/mixins/rules'
export default {
  name: 'Valoracion',
  mixins: [rulesMixin],
  components: {
    PopUpEditar
  },
  props: ['valoracion', 'accion'],
  data() {
    return {
      nombresFicticios: null,
      servicios: null,
      puntuacion: [1, 2, 3, 4, 5],
      valid: false
    }
  },
  async created() {
    const data = await this.getUsuarios()
    this.nombresFicticios = data

    const data2 = await this.getServicios()
    this.servicios = data2
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({
      getUsuarios: 'ratings/getFakeUsers',
      addAlert: 'alerts/add',
      getServicios: 'servicios/getServiciosTipo'
    }),
    enviarCrear() {
      this.$refs.form.validate()
      if (!this.valid) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      this.$emit('crear', this.valoracion)
    }
  }
}
</script>
<style></style>
