<template>
  <div>
    <PopUpEditar
      nombre="GESTIÓN DE NUVA COINS"
      :accion="accion"
      :tipo="'Solicitud de Nuva Coins'"
      :masculino="false"
      @enviarEditar="enviarEditar()"
      @enviarCrear="enviarCrear()"
      @cancelar="$emit('cancelar')"
    >
      <v-row>
        <v-col cols="12">
          <v-row justify="start" align="center" dense>
            <v-col cols="12" style="margin-top:13px">
              <span class="negrita">Producto comprado:</span>
              <span>{{ gestion.product.title }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row justify="start" align="center" dense>
            <v-col cols="4">
              <span class="negrita">Precio:</span>
              <span>{{ gestion.price | currency }}</span>
            </v-col>
            <v-col cols="4">
              <span class="negrita">Fecha: </span>
              <span>{{ gestion.fecha }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </PopUpEditar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PopUpEditar from '@/components/generic/PopUpEditar.vue'
import rulesMixin from '@/mixins/rules'
export default {
  name: 'NuvaCoins',
  mixins: [rulesMixin],
  components: {
    PopUpEditar
  },
  props: ['gestion', 'accion'],
  data() {
    return {}
  },
  async created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>
<style scoped lang="sass">
.negrita
  font-weight: bold
  margin-right: 10px
</style>
