import api from '@/api/api'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getNuvaCoins({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .get('/cms/nuvacoins/get_list_nuvacoins', {
            params: obj
          })
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async validarNuvaCoins({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/cms/nuvacoins/validate_nuvacoins', obj)
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async rechazarNuvaCoins({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/cms/nuvacoins/cancel_nuvacoins', obj)
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  },
  getters: {}
}
