import api from '@/api/api'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getSales({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .get('/cms/sales/get_list_sales', {
            params: obj
          })
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async updateSale({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api.post('/cms/sales/update_sale', obj).then(response => {
          console.log(response)
          if (response.data.ok) {
            resolve(response.data.data)
          } else {
            dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
            reject(response.data)
          }
        })
      })
    },
    async deleteSale({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/cms/sales/delete_sale', obj)
          .then(response => {
            if (response.data.error == null) {
              resolve()
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch('alerts/add', { text: e, color: 'error' }, { root: true })
            reject(e)
          })
      })
    }
  },
  getters: {}
}
