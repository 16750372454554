import api from '@/api/api'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getProducts({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .get('/cms/products/get_list_products', {
            params: obj
          })
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async updateProduct({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.set('id', obj.id)
        formData.set('title', obj.title)
        formData.set('text', obj.text)
        formData.set('category', obj.category)
        if (obj.image) {
          formData.append('image', obj.image)
        }
        api
          .post('/cms/products/update_product', formData)
          .then(response => {
            if (response.data.ok) {
              resolve()
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch('alerts/add', { text: e, color: 'error' }, { root: true })
            reject(e)
          })
      })
    },
    async deleteProduct({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/cms/products/delete_product', obj)
          .then(response => {
            if (response.data.ok) {
              resolve()
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch('alerts/add', { text: e, color: 'error' }, { root: true })
            reject(e)
          })
      })
    }
  },
  getters: {}
}
