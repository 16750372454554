var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-layout',{attrs:{"breadcrumbs":[
    {
      text: 'HOME',
      disabled: true,
      exact: true
    }
  ]}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',{},[_c('v-col',{attrs:{"cols":"11","no-gutters":""}},[_c('v-row',_vm._l((_vm.seccionesAdmin),function(seccion){return _c('v-col',{key:seccion.link,attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"height":"270","hover":true},on:{"click":function($event){return _vm.seleccionarSeccion(seccion.link, 1)}}},[_c('v-img',{attrs:{"src":seccion.image,"height":"200px"}}),_c('v-card-title',{staticClass:"primary--text text-h6 py-2",staticStyle:{"font-weight":"500","word-break":"normal","line-height":"25px"}},[_vm._v(" "+_vm._s(seccion.titulo)+" ")])],1)],1)}),1)],1)],1),_c('v-row',[_c('h2',{staticClass:"primary--text",staticStyle:{"padding":"0"}},[_vm._v(" Otras herramientas ")])]),_c('v-row',[_c('v-divider',{staticClass:"primary"})],1),_c('v-row',[_c('v-btn',{staticClass:"primary font-weight-bold mt-4",attrs:{"href":_vm.$descargarEncuestas(),"target":"_blank"}},[_vm._v(" "+_vm._s('Descargar ' + _vm.numeroEncuestas + (_vm.numeroEncuestas > 1 ? ' encuestas' : ' encuesta') + ' realizadas')+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }