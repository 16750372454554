<template>
  <v-container light fluid style="margin-top:100px">
    <v-row no-gutters justify="center" align="center">
      <v-col cols="2" style="display:flex">
        <v-img src="@/assets/images/NUVA_Living_app_icon.png" width="600" max-height="200px"></v-img>
      </v-col>
      <v-col cols="12" class="my-10 ">
        <v-row no-gutters justify="center">
          <!-- eslint-disable-next-line -->
              <span class="text-h4 primary--text" style="font-weight: bold">PORTAL DE GESTIÓN DE LA APLICACIÓN</span>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" style="text-align:center">
            <span class="grey--text" style="font-size:19px"
              >Por favor, introduzca una nueva contraseña para su cuenta</span
            >
          </v-col>
        </v-row>
      </v-col>

      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card class="elevation-12" style="background-color: #eeeeee">
            <v-card-text>
              <v-form v-model="valid">
                <v-text-field
                  v-model="password"
                  :name="password"
                  :rules="[rules.required]"
                  type="password"
                  label="Contraseña"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="passwordRepetida"
                  :name="passwordRepetida"
                  :rules="[rules.required, rules.pwIguales(password, passwordRepetida)]"
                  type="password"
                  label="Repetir contraseña"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                :disabled="!valid || desactivarBoton"
                :loading="desactivarBoton"
                color="primary"
                @click="modificarPassword()"
              >
                Modificar contraseña
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import rulesMixin from '../../mixins/rules.js'
export default {
  name: 'modificarPassword',
  mixins: [rulesMixin],
  props: ['id', 'token'],
  data() {
    return {
      password: '',
      passwordRepetida: '',
      valid: false,
      desactivarBoton: false
    }
  },
  methods: {
    ...mapActions({
      modificarPasswordApi: 'login/modificarPassword',
      addAlert: 'alerts/add'
    }),
    async modificarPassword() {
      this.desactivarBoton = true
      if (!this.valid) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return false
      }
      try {
        await this.modificarPasswordApi({
          id: this.id,
          token: this.token,
          password: this.password,
          passwordRepetida: this.passwordRepetida
        })
        this.addAlert({ text: 'Su contraseña ha sido modificada correctamente', color: 'success' })
        this.$router.push({ name: 'login' })
        this.desactivarBoton = false
      } catch (error) {
        this.desactivarBoton = false
        this.addAlert({ text: error, color: 'error' })
      }
    }
  }
}
</script>
