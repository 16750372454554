<template>
<!-- eslint-disable -->
  <v-app>
    <v-main>
      <v-container fluid style="margin-top:100px">
        <v-row no-gutters justify="center" align="center">
          <v-col cols="2" style="display:flex" >
                <v-img src="@/assets/images/NUVA_Living_app_icon.png"  width="600px" max-height="200px" ></v-img>
          </v-col>
          <v-col cols="12" class="my-10 ">
            <v-row no-gutters justify="center">
              <!-- eslint-disable-next-line -->
                <span class="text-h4 primary--text" style="font-weight: bold">PORTAL DE GESTIÓN DE LA APLICACIÓN</span>
            </v-row>
          </v-col>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
              <v-card class="elevation-12" style="background-color: #eeeeee">
                <div class="px-15 pt-8">
                  <!-- <v-img contain :src="require('@/assets/logo_horizontal.png')" transition="scale-transition" /> -->
                </div>
                <v-card-text>
                  <v-form v-model="valid" ref="form">
                    <v-text-field
                      label="Email"
                      :rules="[rules.required, rules.email, rules.max(200)]"
                      name="login"
                      prepend-icon="mdi-account"
                      type="text"
                      v-model="email"
                    />
                    <v-text-field
                      label="Contraseña"
                      :rules="[rules.required, rules.max(200)]"
                      name="password"
                      type="password"
                      prepend-icon="mdi-eye"
                      v-model="password"
                    />
                  </v-form>
                   <v-row>
                      <v-spacer></v-spacer>
                      <v-btn @click="resetPassword()" plain small  style="text-transform: unset !important; margin-top: 0">¿Olvidó la contraseña?</v-btn>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn 
                    class="mr-4 mb-3" 
                    color="primary"                   
                    :disabled="(email == '' || waiting)"
                    :loading="waiting"
                    @click="mLogin()"
                  >
                    Iniciar sesión
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-row>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rulesMixin from '@/mixins/rules'
export default {
  mixins: [rulesMixin],
  data() {
    return {
      valid: false,
      email: '',
      password: '',
      waiting: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    })
  },
  methods: {
    ...mapActions({
      login: 'login/login',
      addAlert: 'alerts/add'
    }),
    resetPassword() {
      this.$router.push({ name: 'resetearPassword' })
    },
    async mLogin() {
      this.$refs.form.validate()
      if (!this.valid) {
        this.addAlert({ text: 'Por favor, compruebe los campos en rojo', color: 'error' })
        return
      }
      try {
        this.waiting = true
        await this.login({
          email: this.email,
          password: this.password
        })
        this.addAlert({ text: 'Bienvenido', color: 'success' })
        this.$router.push({ name: 'home' })
        this.waiting = false
      } catch (e) {
        this.waiting = false
        this.addAlert({ text: e, color: 'error' })
        return
      }
    }
  }
}
</script>
