<template>
  <div style="position:fixed; bottom:0; left:0; right:0;z-index:203;">
    <transition-group name="slide-y-reverse-transition" tag="div">
      <v-alert
        style="margin-top:0; margin-bottom:0; border: 0px;"
        v-for="alert in getAlerts"
        :color="getAlertColor(alert.color)"
        :icon="getIcon(alert.color)"
        dismissible
        transition="slide-y-reverse-transition"
        :value="true"
        :key="alert.id"
        @input="deleteAlert(alert)"
      >
        <span :class="getTextColor(alert.color)">{{ alert.text }}</span>
      </v-alert>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      getAlerts: 'alerts/getAll'
    })
  },
  methods: {
    ...mapActions({
      deleteAlert: 'alerts/delete'
    }),
    getAlertColor(color) {
      switch (color) {
        case 'success':
          return 'light-green lighten-4'
        case 'warning':
          return 'warning lighten-3'
        case 'error':
          return 'red lighten-4'
      }
    },
    getTextColor(color) {
      switch (color) {
        case 'success':
          return 'light-green--text text--darken-3'
        case 'warning':
          return 'warning--text text--darken-1'
        case 'error':
          return 'red--text text--darken-3'
      }
    },
    getIcon(color) {
      switch (color) {
        case 'success':
          return 'mdi-check-circle'
        case 'warning':
          return 'mdi-alert'
        case 'error':
          return 'mdi-alert-outline'
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.v-alert.light-green.lighten-4 >>> .v-alert__icon.v-icon,
.v-alert.light-green.lighten-4 >>> .v-alert__dismissible .v-icon
  color: map-get($green, "darken-3") !important

.v-alert.warning.lighten-3 >>> .v-alert__icon.v-icon,
.v-alert.warning.lighten-3 >>> .v-alert__dismissible .v-icon
  color: var(--v-warning-darken1) !important

.v-alert.red.lighten-4 >>> .v-alert__icon.v-icon,
.v-alert.red.lighten-4 >>> .v-alert__dismissible .v-icon
  color: map-get($red, "darken-3") !important
</style>
