<template>
  <div>
    <v-card>
      <v-card-title class="text-h5 primary white--text justify-center font-weight-bold" v-if="accion === 'editar'"
        >Editar {{ tipo }}
      </v-card-title>
      <v-card-title class="text-h5 primary white--text justify-center font-weight-bold" v-else-if="accion === 'ver'"
        >{{ tipo }}
      </v-card-title>
      <v-card-title class="text-h5 primary white--text justify-center font-weight-bold" v-else
        >{{ !masculino ? 'Nueva' : 'Nuevo' }} {{ tipo }}</v-card-title
      >
      <v-divider></v-divider>
      <v-container style="padding:20px">
        <v-row>
          <v-col cols="12">
            <slot />
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn color="primary" text @click="$emit('cancelar')" class="font-weight-bold">
          <v-icon>mdi-chevron-left</v-icon> Volver
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="primary font-weight-bold" v-if="accion === 'editar'" @click="$emit('enviarEditar')">
          Editar {{ tipo }}
        </v-btn>
        <v-btn class="primary font-weight-bold" v-else-if="accion === 'crear'" @click="$emit('enviarCrear')">
          Crear {{ tipo }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'popUpEditar',
  props: ['tipo', 'nombre', 'accion', 'masculino', 'plural'],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({}),
    articulo() {
      if (this.plural) {
        return this.masculino ? 'los' : 'las'
      } else {
        return this.masculino ? 'el' : 'la'
      }
    }
  },
  created() {},
  methods: {
    ...mapActions({})
  },
  watch: {}
}
</script>
