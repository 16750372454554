<template>
<!-- eslint-disable -->
  <v-app>
    <Alerts/>
    <v-app-bar v-if="user != null && user.id != null" app style="background-color: #eeeeee; border-bottom:1px solid #cdcdcd; z-index: 1 !important;" flat>
      <v-row   align="center" no-gutters>
        <v-col cols="auto">
          <v-img src="@/assets/images/logo_nueva.png" width="90" style="cursor: pointer; margin:8px" @click="irHome()"/>
        </v-col>
       
        <v-divider vertical py-2 dark style="margin:5px;margin-left:10px;border-width:2px;border-color:#2f2480;margin:5px" class="my-2"> </v-divider>
        <v-col cols="auto" style="margin-left:15px;">
           <span class="text-h5 primary--text" style="font-weight: bold">PORTAL DE GESTIÓN DE LA APLICACIÓN</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="mr-2">
          <v-row no-gutters align="center">
            <v-col cols="12" class="pa-0 " style="text-align:end">
              <span class=" primary--text" style="font-size: 18px;">{{user.nombre}}</span>
            </v-col>
            <v-col cols="12" class="pa-0 mt-n2" style="text-align:end">
              <span class=" primary--text " style="font-size: 14px;font-weight: bold;">ADMINISTRADOR</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" style="margin-right:10px;">
           <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-img  v-bind="attrs"
                v-on="on" src="@/assets/images/user_icon.png" width="40" style="cursor: pointer; margin:8px"/>
            </template>
            <v-list>
              <v-list-item 
                v-for="(item, index) in items"
                :key="index"
                @click="clickMenu(item.id)"
              >
                <v-list-item-title >{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <v-slide-x-reverse-transition mode="out-in">
        <router-view />
      </v-slide-x-reverse-transition>
    </v-main>
    <v-dialog v-model="popUpPW" v-if="popUpPW" width="800" style="z-index: 999 !important;">
      <Alerts v-if="popUpPW"/>
      <PopUpCambioPW @confirmar="cambiarPw($event)" @cancelar="popUpPW = false" />
    </v-dialog>
  </v-app>
</template>

<script>
import Alerts from '@/components/Alerts'
import PopUpCambioPW from '@/components/popUps/PopUpCambioPW'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    Alerts,
    PopUpCambioPW
  },
  data() {
    return {
      items: [
        { title: 'Cambiar contraseña', id: 1 },
        { title: 'Cerrar sesión', id: 2 }
      ],
      popUpPW: false
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'login/loggedIn',
      user: 'login/user'
    }),
    breadcrumbs() {
      let retorno = [
        {
          text: 'HOME',
          exact: true,
          to: { name: 'home' }
        }
      ]
      return retorno
    }
  },
  methods: {
    ...mapActions({
      logoutBack: 'login/logout',
      cambiarContraseña: 'login/cambiarContraseña',
      addAlert: 'alerts/add'
    }),
    irHome() {
      if (this.$router.currentRoute.name == 'home') {
        return
      }
      this.$router.push('/')
    },
    clickMenu(id) {
      switch (id) {
        case 1:
          this.cambiarPwPopUp()
          break
        case 2:
          this.logout()
          break
      }
    },
    cambiarPwPopUp() {
      this.popUpPW = true
    },
    async cambiarPw(event) {
      try {
        this.waiting = true
        await this.cambiarContraseña(event)
        this.addAlert({ text: 'Contraseña cambiada con éxito', color: 'success' })
        this.popUpPW = false
      } catch (e) {
        this.waiting = false
        this.addAlert({ text: e, color: 'error' })
        return
      }
    },
    async logout() {
      await this.logoutBack()
    }
  }
}
</script>

<style scoped lang="sass">
.sub-app-bar
  width: 100%
  position: fixed
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 25%)
  background-color: rgb(238,238,238)
  z-index: 2
.v-toolbar__content
  padding: 4px 0 !important
</style>
<style>
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
</style>
