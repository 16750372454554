import axios from 'axios'

export const BASE_URL = process.env.BASE_URL + 'api'
export const IMAGEN_URL = BASE_URL + '/imagenes/'
export const LOGOTIPO_URL = BASE_URL + '/logotipos/'

export const DOCUMENTOS_URL = BASE_URL + '/documentos/get'
const api = axios.create({
  // withCredentials: true,
  baseURL: 'https://nuvaLiving.overtek.es/api/'
  // baseURL: 'http://nuvaLivingdes.overtek.es/api/'
})

export default api
