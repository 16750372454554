<template>
<!-- eslint-disable -->
  <v-app>
    <v-main>
      <v-container fluid style="margin-top:100px">
        <v-row no-gutters justify="center" align="center">
          <v-col cols="2" style="display:flex" >
                <v-img src="@/assets/images/NUVA_Living_app_icon.png"  width="600" max-height="200px" ></v-img>
          </v-col>
          <v-col cols="12" class="my-10 ">
            <v-row no-gutters justify="center">
              <!-- eslint-disable-next-line -->
                <span class="text-h4 primary--text" style="font-weight: bold">PORTAL DE GESTIÓN DE LA APLICACIÓN</span>
            </v-row>
          </v-col>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
              <v-card class="elevation-12" style="background-color: #eeeeee">
                <div class="px-15 pt-8">
                  <!-- <v-img contain :src="require('@/assets/logo_horizontal.png')" transition="scale-transition" /> -->
                </div>
                <v-card-text>
                  <v-form v-model="valid" ref="form">
                    <v-text-field
                      label="Por favor, introduzca su correo electrónico"
                      :rules="[rules.required, rules.email, rules.max(200)]"
                      name="login"
                      prepend-icon="mdi-account"
                      type="text"
                      v-model="email"
                    />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" text @click="volver()"> <v-icon>mdi-chevron-left</v-icon> Volver </v-btn>
                  <v-spacer />
                  <v-btn 
                    class="mr-4 mb-3" 
                    color="primary" 
                    :disabled="email == '' || waiting"
                    :loading="waiting"
                    @click="resetPw()"
                  >
                    Reestablecer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-row>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rulesMixin from '@/mixins/rules'
export default {
  mixins: [rulesMixin],
  data() {
    return {
      valid: false,
      email: '',
      waiting: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    })
  },
  methods: {
    ...mapActions({
      resetPasswordApi: 'login/resetPassword',
      addAlert: 'alerts/add'
    }),
    volver() {
      this.$router.push({ name: 'login' })
    },
    async resetPw() {
      this.desactivarBoton = true
      if (!this.valid) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return false
      }
      try {
        await this.resetPasswordApi({
          email: this.email
        })
        this.addAlert({ text: 'Se le ha enviado un email para reestablecer su contraseña', color: 'success' })
        this.$router.push({ name: 'login' })
        this.desactivarBoton = false
      } catch (error) {
        this.desactivarBoton = false
        this.addAlert({ text: error, color: 'error' })
      }
    }
  }
}
</script>
