<template>
  <page-layout
    :breadcrumbs="[
      {
        text: 'HOME',
        disabled: false,
        exact: true,
        to: '/'
      },
      {
        text: 'PRODUCTOS',
        disabled: true,
        exact: true
      }
    ]"
  >
    <v-container>
      <CabeceraYTablas :tipo="'PRODUCTOS'">
        <v-data-table
          :headers="headers"
          :items="products"
          item-key="id"
          :loading="loading"
          class="elevation-1"
          :options.sync="pagination"
          :server-items-length="totalProducts"
          :footer-props="{
            'items-per-page-text': 'Productos por pagina',
            pageText: '{0}-{1} de {2}',
            'items-per-page-options': [25, 50, 100]
          }"
        >
          <template v-slot:top>
            <v-row align="end" justify="space-between" class="mx-4">
              <v-col cols="6">
                <v-text-field v-model="search" @blur="loadProducts()" label="Buscar" class="mx-4"></v-text-field
              ></v-col>
              <v-col cols="auto" justify="space-between" align="end">
                <v-btn class="primary" @click="abrirCrear()">Nuevo producto</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.summary="{ item }">
            <td style="max-width: 500px;">{{ item.summary }}</td>
          </template>
          <template v-slot:no-data>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:no-results>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="primary--text mr-2" @click="abrirVer(item)">
              {{ 'mdi-eye' }}
            </v-icon>
            <v-icon class="primary--text mr-2" @click="abrirEditar(item)">
              {{ 'mdi-pencil' }}
            </v-icon>
            <v-icon class="primary--text iconAction" @click.stop="borrar(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </CabeceraYTablas>
    </v-container>
    <v-dialog v-if="abrirProduct" v-model="abrirProduct" width="80%">
      <Producto
        :producto="producto"
        :ver="viendo"
        :accion="accion"
        @editar="guardarProduct($event, false)"
        @crear="guardarProduct($event, true)"
        @cancelar="cancelarEditar()"
      />
    </v-dialog>
    <v-dialog v-if="abrirPopUpBorrar" v-model="abrirPopUpBorrar" width="600">
      <PopUpEliminar
        @confirmar="borrarProduct()"
        @cancelar="cancelarEditar()"
        :nombre="'Producto'"
        :masculino="true"
        :tipo="'producto'"
      />
    </v-dialog>
  </page-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CabeceraYTablas from '@/components/generic/CabeceraYTablas.vue'
import PopUpEliminar from '@/components/popUps/PopUpEliminar'
import Producto from '@/pages/Producto.vue'
export default {
  name: 'GestionProductos',
  components: {
    CabeceraYTablas,
    PopUpEliminar,
    Producto
  },
  props: [],
  data() {
    return {
      loading: false,
      abrirProduct: false,
      search: '',
      abrirPopUpBorrar: false,
      productoBorrar: null,
      producto: null,
      products: [],
      viendo: false,
      searchFields: ['title', 'summary'],
      totalProducts: 0,
      inicializado: false,
      accion: null,
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['id'],
        sortDesc: [true, false],
        multiSort: true
      }
    }
  },
  async created() {
    this.loading = true
    await this.loadProducts()
    this.loading = false
    this.inicializado = true
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    }),
    headers() {
      return [
        {
          text: 'Título',
          align: 'start',
          sortable: true,
          value: 'title'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getProductsBack: 'products/getProducts',
      updateProduct: 'products/updateProduct',
      deleteProduct: 'products/deleteProduct',
      addAlert: 'alerts/add'
    }),
    borrar(item) {
      this.abrirPopUpBorrar = true
      this.productoBorrar = item
    },
    abrirVer(item) {
      this.accion = 'ver'
      this.abrirProduct = true
      this.producto = item
      this.viendo = true
    },
    abrirEditar(item) {
      this.accion = 'editar'
      this.abrirProduct = true
      this.producto = item
      this.viendo = false
    },
    abrirCrear() {
      this.accion = 'crear'
      this.viendo = false
      this.abrirProduct = true
      this.producto = null
    },
    cancelarEditar() {
      this.viendo = false
      this.accion = ''
      this.abrirProduct = false
      this.abrirPopUpBorrar = false
      this.producto = null
    },
    async guardarProduct(ev, crear) {
      try {
        await this.updateProduct(ev)
        this.addAlert({
          text: 'El producto se ha ' + (crear ? 'creado' : 'editado') + ' correctamente',
          color: 'success'
        })
        this.accion = ''
        this.loading = true
        await this.loadProducts()
        this.abrirProduct = false
      } catch (error) {
        this.loading = false
        this.abrirProduct = false
        return error
      }
    },
    async borrarProduct() {
      try {
        await this.deleteProduct({ producto: this.productoBorrar })
        this.addAlert({ text: 'El producto se ha eliminado correctamente', color: 'success' })
        this.accion = ''
        this.loading = true
        await this.loadProducts()
        this.abrirPopUpBorrar = false
        this.abrirProduct = false
      } catch (error) {
        this.loading = false
        this.abrirPopUpBorrar = false
        this.abrirProduct = false
        return error
      }
    },
    async loadProducts() {
      this.loading = true
      const data = await this.getProductsBack({
        filtros: {
          search: this.search !== null ? this.search : null,
          searchFields: this.searchFields,
          ...this.pagination
        }
      })
      this.products = data.items
      this.totalProducts = data.totalItems
      this.loading = false
    }
  },
  watch: {
    pagination: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadProducts()
        }
      }
    }
  }
}
</script>
<style lang="sass">
.newSelect .v-select__selections
  max-height: 32px
  flex-flow: nowrap
  text-overflow: ellipsis
</style>
