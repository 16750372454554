import uniqid from 'uniqid'

export default {
  namespaced: true,
  state: {
    alerts: []
  },
  mutations: {
    add(state, payload) {
      state.alerts.push(payload)
    },
    delete(state, payload) {
      let aux = state.alerts.findIndex(x => x.id === payload)
      if (aux >= 0) {
        state.alerts.splice(aux, 1)
      }
    }
  },
  actions: {
    add({ commit, getters }, payload) {
      payload.id = uniqid('alert')
      let previous = getters.getAll.find(x => x.text === payload.text)

      if (previous != null) {
        commit('delete', previous.id)
      }
      commit('add', payload)
      setTimeout(() => {
        commit('delete', payload.id)
      }, 3000)
    },
    addNotLoggedIn({ getters, dispatch }) {
      if (getters.getAll.filter(x => x.text === 'Necesita iniciar sesión para acceder a esta página').length === 0) {
        dispatch('add', {
          text: 'Necesita iniciar sesión para acceder a esta página',
          color: 'error'
        })
      }
    },
    addGenerica({ getters, dispatch }) {
      if (getters.getAll.filter(x => x.text === 'Necesita iniciar sesión para acceder a esta página').length === 0) {
        dispatch('add', {
          text: 'Hubo un error, por favor intentelo de nuevo',
          color: 'error'
        })
      }
    },
    delete({ commit }, payload) {
      commit('delete', payload)
    }
  },
  getters: {
    getAll(state) {
      return state.alerts
    }
  }
}
