<template>
  <div>
    <PopUpEditar
      nombre="PRODUCTO"
      :tipo="'Producto'"
      :accion="accion"
      :masculino="true"
      @enviarEditar="enviarEditar()"
      @enviarCrear="enviarCrear()"
      @cancelar="
        $emit('cancelar')
        resetValues()
      "
    >
      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col cols="12">
            <v-row justify="space-between" align="center" dense>
              <v-col cols="9" style="margin-top:13px">
                <v-text-field
                  style="margin-right: 10px;"
                  v-if="!ver"
                  dense
                  :readonly="ver"
                  label="Título"
                  :style="ver ? 'pointer-events:none' : ''"
                  :rules="[rules.required, rules.max(100)]"
                  v-model="productoEditar.title"
                ></v-text-field>
                <h2 v-else>{{ productoEditar.title }}</h2>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="categorias"
                  label="Categoría"
                  :disabled="ver"
                  :rules="[rules.required]"
                  v-model="productoEditar.category"
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="space-between" dense>
              <v-col cols="12" :class="[ver ? 'noMostrar' : '']">
                <v-textarea
                  v-model="productoEditar.text"
                  :readonly="ver"
                  label="Texto"
                  :style="ver ? 'pointer-events:none' : ''"
                />
              </v-col>
            </v-row>
            <v-row justify="space-between" dense>
              <v-col cols="12" :class="[ver ? 'noMostrar' : '']">
                <FileUploader
                  :max-number="1"
                  class="mt-2"
                  :readonly="ver"
                  style="height:200px; width: 350px"
                  v-model="productoEditar.documentacion"
                  uploadFieldName="documentacion[]"
                  :documentacionValida="docuValido"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </PopUpEditar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PopUpEditar from '@/components/generic/PopUpEditar.vue'
import FileUploader from '@/components/generic/fileUploader/FileUploader'
import rulesMixin from '@/mixins/rules'
export default {
  name: 'Producto',
  mixins: [rulesMixin],
  components: {
    PopUpEditar,
    FileUploader
  },
  props: ['producto', 'ver', 'accion'],
  data() {
    return {
      productoEditar: {
        title: null,
        text: null,
        category: null,
        documentacion: this.producto ? [this.producto.image] : [],
        state: 10
      },
      categorias: ['Electrónica', 'Deporte', 'Juguetes', 'Mascota', 'Cocina', 'Motor', 'Hogar'],
      docuValido: true,
      valid: false,
      modal: false
    }
  },
  async created() {
    if (this.producto != null) {
      this.productoEditar = JSON.parse(JSON.stringify(this.producto))
      this.productoEditar.documentacion = this.producto ? [this.producto.image] : []
    }
  },
  computed: {
    ...mapGetters({}),
    cambiado() {
      return this.producto != null && JSON.stringify(this.producto) != JSON.stringify(this.productoEditar)
    }
  },
  methods: {
    ...mapActions({
      addAlert: 'alerts/add'
    }),
    resetValues() {
      this.productoEditar.title = null
      this.productoEditar.text = null
    },
    enviarEditar() {
      this.$refs.form.validate()
      this.docuValido = this.productoEditar.documentacion.length > 0
      if (!this.valid || !this.docuValido) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      if (this.productoEditar.documentacion[0].file != null) {
        this.productoEditar.image = this.productoEditar.documentacion[0].file
      } else {
        this.productoEditar.image = null
      }
      this.$emit('editar', this.productoEditar)
    },
    enviarCrear() {
      this.$refs.form.validate()
      this.docuValido = this.productoEditar.documentacion.length > 0
      if (!this.valid || !this.docuValido) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      if (this.productoEditar.documentacion[0].file != null) {
        this.productoEditar.image = this.productoEditar.documentacion[0].file
      } else {
        this.productoEditar.image = null
      }
      this.$emit('crear', this.productoEditar)
    }
  }
}
</script>
<style>
.noMostrar .editr--toolbar {
  display: none;
}
.noMostrar .editr {
  border: none;
}
</style>
