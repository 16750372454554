import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from '@/plugins/vuetify'
import '@/plugins/components'
import '@/plugins/errorHandler' // importante que esté despues de vuetify
import '@/plugins/styles' // importante que esté despues de vuetify
import '@/plugins/components'
import moment from 'moment'
import { BASE_URL } from '@/api/api'

Vue.config.productionTip = false
const formatterCurrency = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
})

const formatterNoDecimalsCurrency = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('currency', function(value) {
  if (value === null || value === undefined) {
    return formatterCurrency.format(0)
  }
  return formatterCurrency.format(value)
})

Vue.filter('currencyNoDecimals', function(value) {
  if (value === null || value === undefined) {
    return formatterNoDecimalsCurrency.format(0)
  }
  return formatterNoDecimalsCurrency.format(value)
})
Vue.mixin({
  methods: {
    removeDecimalInput(valor) {
      return Number(this.$options.filters.removeDecimalInput(valor))
    },
    copyObject(originalObj, copyObj) {
      Object.keys(originalObj).forEach(clave => {
        this.$set(copyObj, clave, originalObj[clave])
      })
    },
    $image(image) {
      if (image) {
        if (image.documentoGrande) {
          return image.documentoGrande
        } else {
          return 'https://nuvaLiving.overtek.es/' + image.url
          // return BASE_URL + image.url
        }
      } else {
        return require('@/assets/images/products.jpg')
      }
    },
    decimalInput(valor) {
      return this.$options.filters.decimalInput(valor)
    },
    $descargarEncuestas() {
      return BASE_URL + '/cms/surveys/get_excel_surveys'
    },
    $descargarReservas(filtros) {
      return BASE_URL + '/cms/services/get_excel_reservations' + encodeURI('?filtros=' + JSON.stringify(filtros))
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
