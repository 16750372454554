import { render, staticRenderFns } from "./GestionNuvaCoins.vue?vue&type=template&id=1eddb316&scoped=true"
import script from "./GestionNuvaCoins.vue?vue&type=script&lang=js"
export * from "./GestionNuvaCoins.vue?vue&type=script&lang=js"
import style1 from "./GestionNuvaCoins.vue?vue&type=style&index=1&id=1eddb316&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eddb316",
  null
  
)

export default component.exports