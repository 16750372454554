var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['dropbox', _vm.maxNum > 1 ? 'paddingDropbox' : '', _vm.documentacionValida ? '' : 'dropbox-error error--text']},[_c('div',{class:[
      'dropbox-background',
      !_vm.canDrop ? 'remove-drop' : '',
      _vm.maxNum > 1 ? 'paddingDropbox' : '',
      _vm.hovering ? 'hover' : ''
    ],style:(_vm.maxNum > 1 ? { height: _vm.value.length * 60 + 60 + 'px' } : {}),on:{"mouseover":_vm.mouseOver,"mouseout":_vm.mouseOut,"dragover":function($event){$event.stopPropagation();return _vm.dragOver.apply(null, arguments)},"dragleave":_vm.dragLeave}},[_c('input',{class:['input-file', !_vm.canDrop ? 'remove-drop' : ''],style:({ height: '100%' }),attrs:{"type":"file","multiple":_vm.maxNum > 1,"name":_vm.uploadFieldName,"disabled":!_vm.canDrop,"accept":"image/x-png,image/jpeg"},on:{"change":function($event){_vm.filesChange($event.target.name, $event.target.files)
        $event.target.value = ''},"drop":function($event){$event.stopPropagation();}}})]),(_vm.value.length === 0)?_c('v-col',{class:['dropbox-message', _vm.documentacionValida ? '' : 'error--text'],attrs:{"cols":"auto"}},[_c('v-icon',{class:['dropbox-message', _vm.documentacionValida ? '' : 'error--text'],staticStyle:{"font-size":"50px"}},[_vm._v("mdi-upload")]),_c('div',[_vm._v("Arrastre "+_vm._s(_vm.maxNum > 1 ? 'archivos' : 'un archivo')+" o haga clic")])],1):_vm._e(),(_vm.value.length > 0)?_c('draggable',_vm._b({staticClass:"image-list",on:{"start":_vm.onStart,"end":_vm.onEnd},model:{value:(_vm.uploadedFilesComputed),callback:function ($$v) {_vm.uploadedFilesComputed=$$v},expression:"uploadedFilesComputed"}},'draggable',{ disabled: _vm.maxNum === 1 || _vm.value.length < 2, forceFallback: true },false),_vm._l((_vm.value),function(item,index){return _c('div',{key:item.id ? item.id : item.id_temporal,class:[
        'image-list-item',
        _vm.maxNum > 1 && _vm.value.length > 1 ? 'draggable' : 'noDrag',
        _vm.dragging ? 'hide-remove' : ''
      ],style:(index === 0 && _vm.maxNum > 1
          ? { 'margin-top': '20px' }
          : index === _vm.value.length - 1 && _vm.maxNum > 1
          ? { 'margin-bottom': '20px' }
          : {})},[_c('FilePreview',{class:[_vm.esNoticia ? 'image-list-image-noticia' : 'image-list-image', _vm.value.length < 2 ? 'noDrag' : ''],attrs:{"file":item,"readonly":_vm.readonly},on:{"remove":function($event){return _vm.remove(index)}}})],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }