import Vue from 'vue'

Vue.config.errorHandler = (error, vm, info) => {
  console.info(info)
  console.error(error)
  if (error.response) {
    vm.$store.dispatch('alerts/add', { text: error.response.data, color: 'error' })
    if (error.response.status === 403) {
      vm.$router.push({ name: 'login' })
    }
  } else {
    vm.$store.dispatch('alerts/add', { text: error, color: 'error' })
  }
}
