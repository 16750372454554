<template>
  <v-card>
    <v-card-title
      justify="center"
      class="text-h5 font-weight-bold justify-center"
      :style="'background-color: ' + this.$vuetify.theme.themes.light.primary + '; color: white'"
    >
      Validar Nuva Coins
    </v-card-title>

    <v-card-text class="text-center my-5" color="primary"
      ><span class="text-h6 mt-4"> ¿Seguro que desea validar los Nuva Coins? </span>
      <div>
        <v-form v-model="valid" ref="form">
          <v-text-field
            style="margin:0 20%;"
            label="Nuva coins"
            :rules="[rules.required, rules.integerNumber]"
            name="nuvacoins"
            type="text"
            v-model="cantidad"
          />
        </v-form>
      </div>

      <span class="mt-4" style="display: block;">
        (esta acción es irreversible)
      </span>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" text @click="$emit('cancelar')"> <v-icon>mdi-chevron-left</v-icon> Volver </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="enviarCrear()">
        VALIDAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import rulesMixin from '@/mixins/rules'
export default {
  name: 'NuvaCoins',
  mixins: [rulesMixin],
  props: ['gestion', 'accion'],
  data() {
    return {
      valid: false,
      cantidad: null
    }
  },
  async created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({
      addAlert: 'alerts/add'
    }),
    enviarCrear() {
      this.$refs.form.validate()
      if (!this.valid) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      this.$emit('confirmar', this.cantidad)
    }
  }
}
</script>
<style scoped lang="sass">
.negrita
  font-weight: bold
  margin-right: 10px
</style>
