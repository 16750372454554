<template>
  <page-layout
    :breadcrumbs="[
      {
        text: 'HOME',
        disabled: false,
        exact: true,
        to: '/'
      },
      {
        text: 'PROMOCIONES',
        disabled: true,
        exact: true
      }
    ]"
  >
    <v-container>
      <CabeceraYTablas :tipo="'PROMOCIONES'">
        <v-data-table
          :headers="headers"
          :items="ofertas"
          :options.sync="pagination"
          item-key="id"
          :loading="loading"
          class="elevation-1"
          :server-items-length="totalPromociones"
          :footer-props="{
            'items-per-page-text': 'Promociones por pagina',
            pageText: '{0}-{1} de {2}',
            'items-per-page-options': [25, 50, 100]
          }"
        >
          <template v-slot:top>
            <v-row align="center" justify="space-between" class="mx-4">
              <v-col cols="6" style="height: 100%;">
                <v-autocomplete
                  :items="promociones"
                  clearable
                  label="Promoción"
                  v-model="promocionSeleccionada"
                  item-text="promotion_desc"
                  item-value="promotion_code"
                ></v-autocomplete>
              </v-col>
              <v-col cols="auto" justify="end" align="center">
                <v-btn class="primary" @click="abrirCrear()">Nueva promoción</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.translatedState="{ item }">
            <span :class="[item.translatedState]">{{ item.translatedState }}</span>
          </template>
          <template v-slot:item.percentage_extra="{ item }">
            <span>{{ item.percentage_extra + ' %' }}</span>
          </template>
          <template v-slot:no-data>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:no-results>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="primary--text mr-2" @click="abrirEditar(item)">
              {{ 'mdi-pencil' }}
            </v-icon>
            <v-icon
              class="primary--text mr-2"
              @click="mostrarPopUp('activar', item)"
              :loading="loading"
              :disabled="item.state == 10"
            >
              {{ 'mdi-check' }}
            </v-icon>
            <v-icon
              class="primary--text mr-2"
              @click="mostrarPopUp('desactivar', item)"
              :loading="loading"
              :disabled="item.state == 20"
            >
              {{ 'mdi-close' }}
            </v-icon>

            <v-icon class="primary--text iconAction" @click.stop="borrar(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </CabeceraYTablas>
    </v-container>
    <v-dialog v-if="abrirNew" v-model="abrirNew" width="60%">
      <Promocion
        :promocion="promocion"
        :accion="accion"
        @editar="guardarPromocion($event, false)"
        @crear="guardarPromocion($event, true)"
        @cancelar="cancelarCrearEditar()"
      />
    </v-dialog>
    <v-dialog v-if="abrirPopUp" v-model="abrirPopUp" width="600">
      <PopUpConfirmarRechazar
        @confirmar="accion === 'activar' ? actualizarPromocion(true) : actualizarPromocion(false)"
        @cancelar="cancelarActualizar()"
        :accion="accion"
        :elemento="'promoción'"
        :mostrarIrreversible="false"
        :mostrarObservaciones="false"
      />
    </v-dialog>
    <v-dialog v-if="abrirPopUpBorrar" v-model="abrirPopUpBorrar" width="600">
      <PopUpEliminar
        @confirmar="borrarPromocion()"
        @cancelar="cancelarBorrar()"
        :nombre="'Promocion'"
        :tipo="'promocion'"
        :masculino="false"
      />
    </v-dialog>
  </page-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PopUpEliminar from '@/components/popUps/PopUpEliminar'
import CabeceraYTablas from '@/components/generic/CabeceraYTablas.vue'
import PopUpConfirmarRechazar from '@/components/popUps/PopUpConfirmarRechazar'
import Promocion from '@/pages/Promocion.vue'
export default {
  name: 'GestionPromociones',
  components: {
    CabeceraYTablas,
    PopUpConfirmarRechazar,
    Promocion,
    PopUpEliminar
  },
  props: [],
  data() {
    return {
      loading: false,
      abrirServicio: false,
      accion: null,
      abrirPopUp: false,
      abrirNew: false,
      abrirPopUpBorrar: false,
      promocionSeleccionada: null,
      promocion: null,
      promociones: [],
      ofertas: [],
      totalPromociones: 0,
      inicializado: false,
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['service_id'],
        sortDesc: [true],
        multiSort: true
      }
    }
  },
  async created() {
    this.loading = true
    this.promociones = await this.loadPromotions()
    await this.loadPromociones()
    this.loading = false
    this.inicializado = true
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    }),
    headers() {
      return [
        {
          text: 'Nombre servicio',
          align: 'start',
          sortable: true,
          value: 'servicio.name'
        },
        {
          text: 'Promoción',
          align: 'start',
          sortable: true,
          value: 'promotion_desc'
        },
        {
          text: 'Porcentaje extra nuva coins',
          align: 'center',
          sortable: true,
          value: 'percentage_extra'
        },
        {
          text: 'Estado',
          align: 'start',
          value: 'translatedState'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getPromociones: 'sales/getSales',
      loadPromotions: 'promotions/getPromotions',
      actualizarPromocionBack: 'sales/updateSale',
      borrarPromocionBack: 'sales/deleteSale',
      addAlert: 'alerts/add'
    }),
    borrar(item) {
      this.abrirPopUpBorrar = true
      this.promocion = item
    },
    abrirEditar(item) {
      this.accion = 'editar'
      this.abrirNew = true
      this.promocion = item
    },
    abrirCrear() {
      this.accion = 'crear'
      this.abrirNew = true
      this.promocion = null
    },
    cancelarBorrar() {
      this.accion = ''
      this.abrirNew = false
      this.abrirPopUpBorrar = false
      this.promocion = null
    },
    async loadPromociones() {
      try {
        this.loading = true
        const data = await this.getPromociones({
          filtros: {
            ...this.pagination,
            promocion: this.promocionSeleccionada
          }
        })
        this.ofertas = data.items
        this.totalPromociones = data.totalItems
        this.loading = false
      } catch (e) {
        this.addAlert({ text: e, color: 'error' })
      } finally {
        this.loading = false
      }
    },
    async guardarPromocion(ev, crear) {
      try {
        await this.actualizarPromocionBack({ promocion: ev, crear: true })
        this.addAlert({
          text: 'La promocion se ha ' + (crear ? 'creado' : 'editado') + ' correctamente',
          color: 'success'
        })
        this.loading = true
        await this.loadPromociones()
      } catch (error) {
        // this.addAlert({ text: error, color: 'error' })
      } finally {
        this.accion = ''
        this.loading = false
        this.abrirNew = false
      }
    },
    async borrarPromocion() {
      try {
        await this.borrarPromocionBack({ promocion: this.promocion })
        this.addAlert({ text: 'La promoción se ha eliminado correctamente', color: 'success' })
        this.accion = ''
        this.loading = true
        await this.loadPromociones()
        this.abrirPopUpBorrar = false
        this.abrirNew = false
      } catch (error) {
        // this.addAlert({ text: error, color: 'error' })
      } finally {
        this.loading = false
        this.abrirPopUpBorrar = false
        this.abrirNew = false
      }
    },
    async actualizarPromocion(activar) {
      try {
        this.loading = true
        this.abrirPopUp = false
        await this.actualizarPromocionBack({ promocion: this.promocion, crear: false })
        this.loading = false
        this.addAlert({ text: 'Promoción ' + (activar ? 'activada' : 'desactivada') + ' con éxito', color: 'success' })
        this.promocion = null
        await this.loadPromociones()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    mostrarPopUp(accion, promocion) {
      this.accion = accion
      this.promocion = JSON.parse(JSON.stringify(promocion))
      this.promocion.state = accion == 'activar' ? 10 : 20
      this.abrirPopUp = true
    },
    cancelarCrearEditar() {
      this.abrirNew = false
      this.promocion = null
    },
    cancelarActualizar() {
      this.abrirPopUp = false
      this.promocion = null
    }
  },
  watch: {
    pagination: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadPromociones()
        }
      }
    },
    promocionSeleccionada: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadPromociones()
        }
      }
    }
  }
}
</script>
<style lang="sass"></style>
<style scoped>
.Activada {
  color: rgb(58, 87, 2);
}

.Desactivada {
  color: rgb(202, 3, 3);
}
</style>
