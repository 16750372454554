<template>
<!-- eslint-disable -->
  <div style="height: 100%; z-index:2">
    
      <v-breadcrumbs :items="breadcrumbs" class="font-weight-bold" >
      <v-icon  slot="divider">mdi-chevron-right</v-icon>
    </v-breadcrumbs>
    
    
    <v-container >
      <v-row v-if="titulo!=null" no-gutters justify="center">
        <span class="text-h5 primary--text mt-8" style="font-weight: bold">{{titulo}}</span>
      </v-row>
      <slot/>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    },
    titulo: {
      type: String,
      default: null
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
