import store from '@/store'

const inicializar = async function() {
  if (!store.getters['login/loaded']) {
    store.dispatch('login/restore')
    store.dispatch('login/setLoaded', true)
  }
  return true
}

const comprobarAuth = async function(to, next) {
  try {
    if (to.matched.some(route => route.meta.requiresAuth)) {
      if (store.getters['login/loggedIn'] && !store.getters['login/sessionChecked']) {
        // comprobar session
        store.dispatch('login/setCheckingSession', true)
        await store.dispatch('login/checkSession')
        store.dispatch('login/setCheckingSession', false)
      }
      if (!store.getters['login/loggedIn'] && !store.getters['login/sessionChecked']) {
        // comprobar que estoy logueado
        next({ name: 'login' })
        return false
      }
    } else {
      if (store.getters['login/loggedIn'] && to.name === 'login' && !store.getters['login/sessionChecked']) {
        next({ name: 'home' })
        return true
      }
    }
    return true
  } catch (error) {
    next({ name: 'login' })
    return false
  }
}

const beforeEach = async function(to, from, next) {
  store.dispatch('login/setCheckingSession', false)
  if ((await inicializar()) && (await comprobarAuth(to, next))) {
    next()
  }
}

export default beforeEach
