<template>
  <page-layout
    :breadcrumbs="[
      {
        text: 'HOME',
        disabled: true,
        exact: true
      }
    ]"
  >
    <v-container class="pt-0">
      <v-row class="">
        <v-col cols="11" no-gutters>
          <v-row>
            <v-col v-for="seccion in seccionesAdmin" :key="seccion.link" cols="4">
              <v-card class="mx-auto " height="270" :hover="true" @click="seleccionarSeccion(seccion.link, 1)">
                <v-img :src="seccion.image" height="200px"></v-img>

                <v-card-title
                  class=" primary--text text-h6 py-2"
                  style="font-weight:500; word-break: normal; line-height: 25px;"
                >
                  {{ seccion.titulo }}
                </v-card-title>
              </v-card>
            </v-col></v-row
          ></v-col
        >
      </v-row>
      <v-row>
        <h2 class="primary--text" style="padding:0">
          Otras herramientas
        </h2>
      </v-row>
      <v-row>
        <v-divider class="primary"></v-divider>
      </v-row>
      <v-row>
        <v-btn class="primary font-weight-bold mt-4" :href="$descargarEncuestas()" target="_blank">
          {{ 'Descargar ' + numeroEncuestas + (numeroEncuestas > 1 ? ' encuestas' : ' encuesta') + ' realizadas' }}
        </v-btn>
      </v-row>
    </v-container>
  </page-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'home',
  components: {},
  props: ['local'],
  data() {
    return {
      locales: [],
      loading: false,
      guardando: false,
      cambios: false,
      iniciado: false,
      numeroEncuestas: 220,
      seccionesAdmin: {
        servicios: {
          id: 1,
          titulo: 'Servicios',
          link: 'servicios',
          image: require('@/assets/images/servicios.jpg')
        },
        noticias: {
          id: 2,
          titulo: 'Noticias',
          link: 'noticias',
          image: require('@/assets/images/noticias.jpg')
        },
        productos: {
          id: 3,
          titulo: 'Productos',
          link: 'productos',
          image: require('@/assets/images/products.jpg')
        },
        valoraciones: {
          id: 4,
          titulo: 'Valoraciones',
          link: 'valoraciones',
          image: require('@/assets/images/valoraciones.jpg')
        },
        promociones: {
          id: 5,
          titulo: 'Promociones',
          link: 'promociones',
          image: require('@/assets/images/promociones.jpg')
        },
        cashback: {
          id: 6,
          titulo: 'Gestión de Cash Back',
          link: 'nuva_coins',
          image: require('@/assets/images/cashback.jpg')
        }
      }
    }
  },
  async created() {
    let respuesta = await this.getSurveysNumber()
    this.numeroEncuestas = respuesta.number
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    })
  },
  methods: {
    ...mapActions({
      getSurveysNumber: 'surveys/getSurveysNumber'
    }),
    seleccionarSeccion(link) {
      this.$router.push({ name: link })
    }
    /* async methodPrueba() {
      try {
      } catch (error) {
        
      }
    }, */
  },
  watch: {}
}
</script>
