<template>
  <div>
    <v-card>
      <v-img height="200" :src="getRouteImage" cover class="text-white">
        <v-row no-gutters style="align-content: center;justify-content: right;height: 100%;">
          <h1 class="tituloServicio">
            {{ reserva.servicio.name }}
          </h1>
        </v-row>
      </v-img>

      <v-container style="padding:20px; color:#2c3b51;">
        <v-row>
          <v-col cols="12">
            <v-row justify="space-between" dense>
              <v-col cols="auto">
                <h1 dense>{{ reserva.user.mixName }}</h1>
              </v-col>
              <v-col cols="auto">
                <h1 dense>{{ reserva.fecha }}</h1>
              </v-col>
            </v-row>
            <v-row justify="end" dense>
              <h1 dense>{{ reserva.horaInicio }}</h1>
            </v-row>
            <v-row justify="end" dense>
              <h1 dense>{{ reserva.duracion + ' m' }}</h1>
            </v-row>
            <v-row dense style="font-size:20px">
              <span style="margin-right:20px">
                Comentarios del cliente:
              </span>
              <span>
                {{ reserva.comments }}
              </span>
            </v-row>
            <v-row justify="space-between" dense style="font-size:20px">
              <span style="margin-right:20px">
                Observaciones:
              </span>
              <span>
                {{ reserva.observations }}
              </span>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn color="primary" text @click="$emit('cancelar')" class="font-weight-bold">
          <v-icon>mdi-chevron-left</v-icon> Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Servicio',

  props: ['reserva'],
  data() {
    return {}
  },
  async created() {},
  computed: {
    ...mapGetters({}),
    getRouteImage() {
      var images = require.context('../assets/images/')
      let retorno = ''
      switch (this.reserva.service_id) {
        case 1:
          retorno = 'Limpieza.png'
          break
        case 2:
          retorno = 'Car.png'
          break
        case 3:
          retorno = 'Mascotas.png'
          break
        case 4:
          retorno = 'Seguros.jpg'
          break
        case 5:
          retorno = 'Alarma.png'
          break
        case 6:
          retorno = 'Mudanza.png'
          break
      }
      return images('./' + retorno)
    }
  },
  methods: {
    ...mapActions({})
  }
}
</script>
<style>
.noMostrar .editr--toolbar {
  display: none;
}
.tituloServicio {
  font-size: 41px;
  text-shadow: 1px 1px 2px #2c3b51;
  width: 255px;
  color: white;
}
</style>
